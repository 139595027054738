import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import refreshIcon from '../../../../assets/svgs/refresh.svg';
import './style.scss';


const CreditsCard = (props) => {
  const {
    amount = '0',
    credits = '0',
    objectId = -1,
    getPerformer,
    isLoading,
  } = props;

  const onTransactionsClick = () => {
    props.history.push({
      pathname: '/balance',
      state: {
        transaction: true,
      },
    });
  };

  return (
    <Card className="customCard Credits-customCard bg-primary p-4 customCardHeader">
      <CardBody>
        <Row className="mb-4 align-items-center">
          <Col sm={8}>
            <h4 className="amount mb-0">
              {`$${amount}`}
              <sub><small>USD</small></sub>
            </h4>
            <h6 className="amtTitle">Your current balance</h6>
          </Col>
          <Col className="text-right" sm={4}>
            <CardText className="mb-0 creditamount">{`${credits}`}</CardText>
            <h6 className="mb-0 amtTitle">CREDITS</h6>
          </Col>
        </Row>
        <Row>
          <Col sm={7}>
            <Button color="dark" size="sm" onClick={onTransactionsClick} className="mb-0 px-4">Transactions</Button>
          </Col>
          <Col className="text-right" sm={5}>
            <Button className="creditBtn px-3 mb-0 refreshBtn" onClick={() => getPerformer(objectId)}>
              {
                isLoading
                  ? <div className="css-loader mr-1" style={{ position: 'static' }} />
                  : (
                    <img
                      role="presentation"
                      className="mr-1"
                      src={refreshIcon}
                      alt="refresh_credits_avatar"
                      width="16"
                    />
                  )
              }
              <div className="ml-1">Refresh</div>
            </Button>
          </Col>
          <Col className="credit-update-info mt-3 position-static">This information is updated every 5 minutes.</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

CreditsCard.defaultProps = {
  amount: '0',
  credits: '0',
  objectId: -1,
  isLoading: false,
};

CreditsCard.propTypes = {
  amount: PropTypes.string,
  credits: PropTypes.string,
  objectId: PropTypes.number,
  getPerformer: PropTypes.func.isRequired,
  // eslint-disable-next-line
  history: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default CreditsCard;
