/* eslint-disable camelcase */
import React from 'react';
import { Field } from 'redux-form';
import {
  Form, Button, Row, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import publicIp from 'public-ip';
import { v4 as uuidv4 } from 'uuid';
import { UAParser } from 'ua-parser-js';

import { renderField } from '../../utils/formUtils';
import { required } from '../../utils/formUtils/validator';
import CustomModal from '../../components/CustomModal';
import arousrLogo from '../../assets/svgs/logo.svg';
import { getCookies, setCookies } from '../../utils/cookies';
// import LoadingSpinner from '../../utils/LoadingSpinner';
import interceptor from '../../utils/interceptor';
import './style.scss';

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isLoginDisable: false, passwordType: 'password', isShowDisclaimerPopup: false };
  }
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    const userId = localStorage.getItem('loggedInUserId');
    if (JSON.parse(userId)) {
      const { history } = this.props;
      history.push('/dashboard');
    }
  }

  handleLogin = () => {
    const {
      login, history, getAuthenticationToken,
      createChatUser, stingifyValue,
    } = this.props;
    const formValues = JSON.parse(stingifyValue);
    this.setState({
      isLoginDisable: true,
    });
    getAuthenticationToken(formValues)
      .then(() => {
        interceptor();
        (async () => {
          const customerIP = await publicIp.v4();
          const parser = new UAParser();
          const result = parser.getResult();
          const formData = new FormData();
          const deviceId = getCookies('device_id') || uuidv4();
          setCookies('device_id', deviceId, {});
          formData.set('username', formValues.username);
          formData.set('password', formValues.password);
          formData.set('ip', customerIP);
          formData.set('device_id', deviceId);
          formData.set('device_os', result?.os?.name);
          formData.set('app_version', result?.browser?.name);
          formData.set('app_name', 'performer_web');
          formData.set('device_app', 'web');
          login(formData)
            .then((response) => {
              const { value: { data = {} } = {} } = response;
              this.setState({
                isLoginDisable: false,
              });
              if (data && data.perfcharacter) {
                setCookies('user_type', data.perfcharacter[0].user_type, { domain: '.arousr.com' });
              }
              if (data && data.perfperformer) {
                localStorage.setItem('loggedInUserId', data.perfperformer.objid);
                const xmpp_user = data && data.xmpp_user;
                const xmpp_pass = data && data.xmmp_pass;
                const createChatUserdata = {
                  xmpp_user,
                  xmpp_pass,
                  token: 'DR846kTQml',
                };
                createChatUser(createChatUserdata);
                history.push('/dashboard', data);
              }
            })
            .catch((error) => {
              this.setState({
                isLoginDisable: false,
              });
              const { message = '' } = error?.response?.data;
              if (message) {
                toast.error(message);
              }
            });
        })();
      })
      .catch(({ response: { data = {} } = {} }) => {
        this.setState({
          isLoginDisable: false,
        });
        if (data.error === 'Invalid credentials') {
          toast.error('Incorrect username or password. Please check once and try again!');
        }
        // else {
        //   toast.error('Something went wrong. Please try again after some time.');
        // }
      });
  }

  changePassFeildType = () => {
    let valueType = '';
    const { passwordType } = this.state;
    if (passwordType === 'text') {
      valueType = 'password';
    } else {
      valueType = 'text';
    }
    this.setState({
      passwordType: valueType,
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { isLoginDisable = false, passwordType = 'password', isShowDisclaimerPopup } = this.state;
    // if (isLoading) {
    //   return <LoadingSpinner />;
    // }

    // if (error && message) {
    //   toast.error(message);
    // }
    return (
      <>
        <CustomModal
          header="Login"
          body="you will be logged off from Arousr on other devices."
          buttonText="OK"
          footer="CANCEL"
          footerLink={() => this.setState({ isShowDisclaimerPopup: false })}
          open={isShowDisclaimerPopup}
          onClick={() => this.handleLogin()}
          toggle={() => this.setState({ isShowDisclaimerPopup: false })}
        />
        <div className="px-3">
          <Row>
            <Col className="leftCol" />
            <Col>
              <div className="performer-login-base d-flex justify-content-center align-items-center">
                <div className="performer-login w-100">
                  <div className="perf-login-logo text-center mb-4">
                    <span>
                      <img
                        className="img-responsive"
                        width="179"
                        src={arousrLogo}
                        alt="Card cap"
                      />
                    </span>
                  </div>
                  <div className="perf-login-head text-center">
                    {/* <h3 className="mb-0">
                    Welcome Back
                    </h3> */}
                    <p>
                      Login
                    </p>
                  </div>
                  <Form onSubmit={handleSubmit(() => this.setState({ isShowDisclaimerPopup: true }))} className="formWraper mt-5">
                    <Field
                      className="perf-login-username"
                      type="text"
                      label="Username"
                      name="username"
                      component={renderField}
                      validate={[required]}
                      placeholder="Username"
                      validationError="Please provide your username"
                    />
                    <Field
                      label="Password"
                      className="perf-login-password"
                      type={passwordType}
                      name="password"
                      changePassFeildType={this.changePassFeildType}
                      component={renderField}
                      validate={[required]}
                      isPasswordFeild
                      placeholder="Password"
                      validationError="Please provide your password"
                    />
                    <div className="perf-login-btn">
                      <Button
                        type="submit"
                        className="loginBtn mt-4"
                        color="primary"
                        block
                        disabled={isLoginDisable}
                      >
                    login
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>

        </div>
      </>
    );
  }
}


Login.defaultProps = {
  stingifyValue: '',
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getAuthenticationToken: PropTypes.func.isRequired,
  createChatUser: PropTypes.func.isRequired,
  stingifyValue: PropTypes.string,
};

export default Login;
