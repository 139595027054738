/* eslint-disable camelcase */
import React from 'react';
import {
  Card,
  CardBody,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import moment from 'moment';

import Pagination from '../../components/Pagination';
import CreditDetails from './components/CreditDetails';
import CreditsContainer from '../../containers/CreditsContainer';
import LoadingSpinner from '../../utils/LoadingSpinner';
import BalanceRefund from './components/BalanceRefund';
import NoDataAvailable from '../../components/NoDataAvailable';
import './style.scss';

import dollar from '../../assets/svgs/dollar';
import message from '../../assets/svgs/message';
import video from '../../assets/svgs/video';
import autoPlay from '../../assets/svgs/autoPlay';
import call from '../../assets/svgs/call';
import affiliate from '../../assets/svgs/affiliate';
import reverse from '../../assets/svgs/reversetip';
import transactionstar from '../../assets/svgs/transactionstar';
import crownIcon from '../../assets/svgs/icon-crown.svg';
import { showOnlineStatus } from '../../utils/helpers';

class Balance extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleTableOptions = this.handleTableOptions.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleRefundClick = this.handleRefundClick.bind(this);
    this.addProperitesInResults = this.addProperitesInResults.bind(this);

    this.state = {
      view: 'paymentHistory',
      currentPage: 1,
    };
    this.pageSize = 20;
  }

  componentDidMount() {
    const {
      dashboardData: { perfperformer },
      getPerformer,
      location,
      getPayoutDetails,
    } = this.props;
    const objid = perfperformer && perfperformer.objid;
    if (objid) {
      getPerformer(objid);
    }

    if (location.state && location.state.transaction) {
      this.handleTableChange('transaction');
    }

    this.handleTableOptions();
    window.scrollTo(0, 0);

    if (perfperformer?.perf2cst_contact) {
      getPayoutDetails(perfperformer.perf2cst_contact);
    }
  }


  mapDescriptionDataToColumn = (transDescriptionObj = {}) => {
    const { trans_description: transDescription, trans_type: transType } = transDescriptionObj;
    switch (transType) {
      case 'TIP001':
        return (
          <div className="text-center description-name">
            { dollar(20, 20) }
            {transDescription}
          </div>
        );
      case 'SMS001':
        return (
          <div className="text-center description-name">
            { message(20, 20) }
            {transDescription}
          </div>
        );
      case 'DE0003':
        return (
          <div className="text-center description-name">
            { autoPlay(20, 20) }
            {transDescription}
          </div>
        );
      case 'DE0006':
        return (
          <div className="text-center description-name">
            { call(20, 20) }
            {transDescription}
          </div>
        );
      case 'DE0007':
        return (
          <div className="text-center description-name">
            { video(20, 20) }
            {transDescription}
          </div>
        );
      case 'BON003':
        return (
          <div className="text-center description-name">
            { affiliate(20, 20) }
            {transDescription}
          </div>
        );
      case 'TIP002':
        return (
          <div className="text-center description-name">
            { reverse(20, 20) }
            {transDescription}
          </div>
        );
      case 'BON005':
        return (
          <div className="text-center description-name">
            { transactionstar(20, 20) }
            {transDescription}
          </div>
        );
      case 'BON007':
        return (
          <div className="text-center description-name">
            { transactionstar(20, 20) }
            {transDescription}
          </div>
        );
      case 'BON008':
        return (
          <div className="text-center description-name">
            { transactionstar(20, 20) }
            {transDescription}
          </div>
        );
      default:
        return '';
    }
  }

  myTipFormatter = (isRefunded, objid) => (
    <BalanceRefund
      isRefunded={isRefunded}
      objid={objid}
      handleRefundClick={this.handleRefundClick}
    />
  )

  screenNameFormatter = (name, row) => {
    const {
      customer2cst_contact: {
        profile_pic = null,
        is_premium_customer = false,
        is_customer_online = false,
        send_email_notification = false,
      } = {},
    } = row;
    let screenNameData;
    console.log('rowrowrowrow', row);
    if (profile_pic) {
      screenNameData = (
        <div className="profile-with-crown">
          <img width="30px" height="30px" src={profile_pic} alt={name} />
          {is_premium_customer ? <img src={crownIcon} alt="crown" className="is_premium_customer" /> : ''}
          {/* {is_customer_online ? <span className="online-small-icon" /> :
          <span className="offline-small-icon" />} */}
          {showOnlineStatus(is_customer_online, send_email_notification)}
        </div>
      );
    } else if (name) {
      screenNameData = (
        <div>
          <svg width="50" height="50" data-jdenticon-value={name} />
          {is_premium_customer ? <img src={crownIcon} alt="crown" className="is_premium_customer" /> : ''}
          {/* {is_customer_online ? <span className="online-small-icon" /> :
          <span className="offline-small-icon" />} */}
          {showOnlineStatus(is_customer_online, send_email_notification)}
        </div>
      );
    } else {
      screenNameData = '';
    }


    return (
      <div className="screen-name">
        {/* {
          profile_pic ? (
            <img width="30px" height="30px" src={profile_pic} alt={name} />
          ) : <svg width="50" height="50" data-jdenticon-value={name} />
        } */}
        {/* <span>{name}</span> */}
        {screenNameData}
        {name ? <span>{name}</span> : <span style={{ marginLeft: '45px' }}>-</span>}
      </div>
    );
  };

  handleTableChange(view) {
    this.setState({
      view,
      currentPage: 1,
    }, () => this.handleTableOptions());
  }

  addProperitesInResults() {
    const { balanceData: { results = [] }, dashboardData: { auth2contact_objid } } = this.props;
    const { view } = this.state;
    let updatedResults = [];

    if (view === 'paymentHistory') {
      let name = '';
      if (auth2contact_objid) {
        name = `${auth2contact_objid.first_name || ''} ${auth2contact_objid.last_name || ''}`;
      }
      updatedResults = results && results.map((result) => {
        const {
          settlement_amount_currency: amt,
          fee_currency: feeCurrency, adjustment_currency: adjustmentCurrency,
          payment_details, status,
        } = result;
        const totalAmount = (Number(amt) + Number(adjustmentCurrency) - Number(feeCurrency));
        return ({
          ...result,
          status: payment_details?.status ? payment_details?.status : status,
          name,
          check_number: totalAmount,
        });
      });
    }
    if (view === 'transaction' || view === 'myTip') {
      updatedResults = results && results.map((result) => {
        const roundOfChange = result && Math.round(result.credit_amount);
        const { trans_type2cfg_credit_trans_type } = result;
        // const { customer2cst_contact: { screen_name = '' } = {} } = result;
        if (trans_type2cfg_credit_trans_type) {
          return ({
            ...result,
            description: this.mapDescriptionDataToColumn(trans_type2cfg_credit_trans_type),
            credit_amount: roundOfChange,
          });
        }
        return ({
          ...result,
          credit_amount: roundOfChange,
        });
      });
    }
    return updatedResults;
  }

  handleRefundClick(tip) {
    const { refundTip } = this.props;
    const { currentPage } = this.state;
    refundTip(tip.objid)
      .then(() => this.handleTableOptions(currentPage));
  }

  handleTableOptions(pageNumber = 1) {
    const {
      dashboardData,
      getPaymentHistory,
      getTransactions,
      getTips,
    } = this.props;
    const { view } = this.state;
    let url = '';
    let cstObjectId = '';
    if (dashboardData && dashboardData.auth2contact_objid) {
      cstObjectId = dashboardData.auth2contact_objid.objid;
    }
    if (view === 'paymentHistory') {
      url = `/cfgsettlementtransactions/list/?settlement2cst_contact=${cstObjectId}&page_size=${this.pageSize}&page=${pageNumber}`;
      getPaymentHistory(url);
    }
    if (view === 'transaction') {
      url = `/cfgcredittransactions/list/?performer2cst_contact=${cstObjectId}&trans_type=SMS001|DE0006|DE0007|DE0003|BON005|BON003|TIP001|TIP002|BON007|BON008&page_size=${this.pageSize}&page=${pageNumber}`;
      getTransactions(url);
    }
    if (view === 'myTip') {
      url = `/cfgcredittransactions/list/?performer2cst_contact=${cstObjectId}&trans_type=TIP001&page_size=${this.pageSize}&page=${pageNumber}`;
      getTips(url);
    }
    this.setState({ currentPage: pageNumber });
  }

  render() {
    const {
      loadingData,
      balanceData: { count, results = [] },
      minPayoutBalance,
      history,
    } = this.props;
    const { view, currentPage } = this.state;
    const statusValues = {
      10: 'Requested',
      20: 'Credit Back',
      30: 'Paid',
      50: 'Bounced',
      40: 'Not Received',
    };
    const dateFormatter = cell => moment(new Date(cell)).format('YYYY-MM-DD hh:mm:ss');

    const statusFormatter = cell => (typeof cell === 'number' ? statusValues[cell] : cell);

    const paymentHistoryColumns = [{
      dataField: 'settlement_id',
      text: 'ID',
    }, {
      dataField: 'created_date',
      formatter: dateFormatter,
      text: 'Date',
      sort: true,
    }, {
      dataField: 'method',
      text: 'Method',
      sort: true,
    }, {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,
      sort: true,
    }, {
      dataField: 'settlement_amount_currency',
      text: 'Amount',
      sort: true,
    }, {
      dataField: 'fee_currency',
      text: 'Fee',
      sort: true,
    }, {
      dataField: 'adjustment_currency',
      text: 'Adjustments',
      sort: true,
    }, {
      dataField: 'check_number',
      text: 'Total',
      sort: true,
    }, {
      dataField: 'currency',
      text: 'Currency',
      sort: true,
    }];

    const transactionColumns = [
      {
        dataField: 'trans_datetime',
        formatter: dateFormatter,
        text: 'Date',
        sort: true,
      }, {
        dataField: 'customer_screen_name',
        text: 'Screen Name',
        formatter: this.screenNameFormatter,
        sort: true,
      }, {
        dataField: 'description',
        text: 'Description',
      }, {
        dataField: 'performer_revenue_credit',
        text: 'Credits',
        sort: true,
      },
    ];

    const myTipColumns = [
      {
        dataField: 'trans_datetime',
        formatter: dateFormatter,
        text: 'Date',
        sort: true,
      }, {
        dataField: 'customer_screen_name',
        text: 'Screen Name',
        formatter: this.screenNameFormatter,
        sort: true,
      }, {
        dataField: 'credit_amount',
        text: 'Credits',
        sort: true,
      }, {
        dataField: 'is_refunded',
        formatter: this.myTipFormatter,
        text: 'Refund',
        sort: true,
      },
    ];

    const tableFields = {
      paymentHistoryColumns,
      transactionColumns,
      myTipColumns,
    };

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc',
    }];

    const renderTableBody = () => {
      if (loadingData) {
        return (
          <div style={{ height: '600px' }}>
            <LoadingSpinner />
          </div>
        );
      }

      const tableComponent = (results && results.length > 0) ? (
        <BootstrapTable
          striped
          bootstrap4
          bordered={false}
          keyField={view === 'paymentHistory' ? 'settlement_id' : 'trans_id'}
          data={this.addProperitesInResults()}
          columns={tableFields[`${view}Columns`]}
          defaultSorted={defaultSorted}
        />
      ) : (
        <div style={{ height: '400px' }}>
          <NoDataAvailable />
        </div>
      );

      return tableComponent;
    };

    return (
      <>
        <Card className={`bg-none ${results && results.length > 0 ? '' : 'height-600'}`}>
          <CreditsContainer
            component={CreditDetails}
            handleTableChange={this.handleTableChange}
            view={view}
            minPayoutBalance={minPayoutBalance}
            history={history}
          />
          <CardBody className="pt-3 bg-dark tableBorderRadius">
            <div className={view === 'paymentHistory' ? 'tableWidthController' : 'controller2'}>
              {renderTableBody()}
            </div>
            {count > 0 && (
              <div className="pull-right">
                <Pagination
                  totalCount={count}
                  pageSize={this.pageSize}
                  onPageClick={this.handleTableOptions}
                  currentPage={currentPage}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

Balance.defaultProps = {
  loadingData: false,
  balanceData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  minPayoutBalance: 0,
};

Balance.propTypes = {
  loadingData: PropTypes.bool,
  dashboardData: PropTypes.shape({
    auth2contact_objid: PropTypes.instanceOf(Object),
    auth_type: PropTypes.string,
    perfcharacter: PropTypes.instanceOf(Array),
    perfperformer: PropTypes.instanceOf(Object),
    xmmp_pass: PropTypes.string,
    xmpp_user: PropTypes.string,
  }).isRequired,
  balanceData: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.string,
    previous: PropTypes.string,
    results: PropTypes.instanceOf(Array),
    minPayoutBalance: '0',
  }),
  getPerformer: PropTypes.func.isRequired,
  getPaymentHistory: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  getTips: PropTypes.func.isRequired,
  refundTip: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  minPayoutBalance: PropTypes.string,
  getPayoutDetails: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default Balance;
