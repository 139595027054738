import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
} from 'reactstrap';
import reviewAvatar from '../../../../assets/svgs/review avatar.svg';
import './style.scss';
import rankOneImg from '../../../../assets/images/rank1.png';
import rankTwoImg from '../../../../assets/images/rank2.png';
import rankThreeImg from '../../../../assets/images/rank3.png';
import enagagementScoreImg from '../../../../assets/images/engagement-score-img.png';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import NoDataAvailable from '../../../../components/NoDataAvailable';

const EngagementScore = (props) => {
  const {
    charObjId,
    engagmentScoreFunction,
  } = props;

  const [engagmentScoreRankingData, setEngagementRankingData] = useState([]);
  const [engagementScores, setEngagementScores] = useState({});
  const [hover, setHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const formatDate = (newDate) => {
    const d = new Date(newDate);
    const months = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    };
    const date = d.getDate();
    const monthName = months[d.getMonth()];
    const formatted = `${date} ${monthName}`;
    return formatted.toString();
  };

  const renderInfo = () => (
    <div className="info_section">
      <p className="dots">
      Engagement Score is calculated every week, based on members engagement in your Chat Sessions.
      The more replies you get from a member in response to the message you send,
      the higher is the engagement.
      You need a minimum of 20 premium eligible sessions in the week to be included.
      Eligible sessions are those with more than 10 messages from you to the customer.
      The Top 5 (Most engaging) hosts will earn a Weekly Bonus.
      </p>
      <p className="winner-text">1st Winner : 3000 credits</p>
      <p className="winner-text">2nd Winner : 2500 credits</p>
      <p className="winner-text">3rd Winner : 2000 credits</p>
      <p className="winner-text">4th Winner : 1500 credits</p>
      <p className="winner-text">5th Winner : 1000 credits</p>
      <p className="dots"> Results are announced every Thursday.</p>
      <p className="dots">
         Earned bonus will get credited to hosts main balance.
         They will be able to see entry in the transactions list.
      </p>
    </div>
  );

  useEffect(() => {
    setIsLoading(true);
    const url = `/perfcharacter/engagement-score/?character_objid=${charObjId}`;
    engagmentScoreFunction(url)
      .then((response) => {
        setEngagementRankingData(response.value.data.data);
        setEngagementScores(response.value.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.result);
        }
      });
  }, []);

  const renderEngagmentScore = engagmentScoreRankingData
  && engagmentScoreRankingData.map((score) => {
    const {
      // objid,
      character,
      primary_pic_url: primaryPicUrl, display_name: displayName,
      credit, rank,
      // engagement_score: engagementScore,
      weekly_sms_engagement_score: engagementScore,
    } = score;

    const engScoreFormated = (Math.round(engagementScore * 100) / 100).toFixed(2);

    return (
      <div key={character} className="reviewDetails engagementListRow">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 d-flex align-items-center">
            <div className="ranks">
              {rank && rank === 1 && <img src={rankOneImg} alt="rank" />}
              {rank && rank === 2 && <img src={rankTwoImg} alt="rank" />}
              {rank && rank === 3 && <img src={rankThreeImg} alt="rank" />}
              {rank && rank === 4 && <span style={{ color: '#f07563' }}>{rank}</span>}
              {rank && rank === 5 && <span style={{ color: '#f07563' }}>{rank}</span>}
            </div>
            <div className="">
              {
                primaryPicUrl
                  ? <img src={primaryPicUrl} alt="ProfilePic" className="review-profile" width="30px" height="30px" />
                  : <img src={reviewAvatar} alt="revew" />
              }
            </div>
            <Col className="HostName"><h6 className="mb-0 pt-1">{displayName}</h6></Col>
          </div>
          <div className="pl-12 text-right">
            <Row className="align-items-center">
              <Col>
                {engScoreFormated}
              </Col>
              <Col className="reviewHead">
                <h6 className="mb-0 pt-1">
                  {credit}
                </h6>
                <span>credits</span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Card className="customCard reviewCard bg-dark h-100 engagementCard">
        <>
          <CardHeader className="customCardHeader">
            <div className="engage-score-head">
              <h2>
                Engagement Rankings
              </h2>
              <svg
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
                role="button"
                tabIndex="-3"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle opacity="0.1" cx="9" cy="9" r="8" fill="#FD7663" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18 13.968 13.968 18 9 18C4.032 18 0 13.968 0 9ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2Z" fill="#FD7663" />
                <rect x="8" y="3" width="2" height="8" fill="#F0705E" />
                <rect x="8" y="12" width="2" height="2" fill="#F0705E" />
              </svg>
            </div>
            <img className="w-100 mb-2" src={enagagementScoreImg} alt="engscore" />
          </CardHeader>
          {hover ? renderInfo() : ''}
        </>
        {errorMessage ? (
          <NoDataAvailable message={errorMessage} className="engagement_No_data" />
        ) : (
          <>
            <div className="text-right column-heads">
              <span className="board">Score Board</span>
              <span className="bonus">Bonus Earned</span>
            </div>
            <CardBody className="mr-10">
              {renderEngagmentScore}
            </CardBody>
            {engagementScores.self_rank ? (
              <>
                <div className="reviewDetails engagementListRow selfRankingCard">
                  <div className="hostRank" style={{ fontSize: '0.9rem' }}>
                    <span style={{ fontWeight: '500' }}>
                        Your Rank:
                    </span>
                    {engagementScores.self_rank.rank
                      ? (
                        <strong>
                          {' '}
                          {engagementScores.self_rank.rank}
                        </strong>
                      ) : <strong>*</strong>}
                  </div>
                  <div className="hostScore" style={{ fontSize: '0.9rem' }}>
                    <span style={{ fontWeight: '500' }}>Your Score:</span>
                    {' '}
                    <strong>
                      { Number(engagementScores.self_rank?.engagement_score).toFixed(2) }
                    </strong>
                  </div>
                </div>
                {!engagementScores.self_rank.rank
                  && <p className="no-rank-para">*No ranking for you as you had less than 20 eligible sessions.</p>
                }
              </>
            ) : ''
              }
            <p className="ranking-note">
              *Rankings are based on engagement score between&nbsp;
              {formatDate(engagementScores.result_publish_date)}
              <span className="mr-1 ml-1">to</span>
              {formatDate(engagementScores.result_expiry_date)}
            </p>
          </>
        )
        }
      </Card>
    </>
  );
};

EngagementScore.defaultProps = {
  charObjId: 0,
};
EngagementScore.propTypes = {
  engagmentScoreFunction: PropTypes.func.isRequired,
  charObjId: PropTypes.number,
};

export default EngagementScore;
