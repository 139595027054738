/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import PropTypes, { object } from 'prop-types';
import { connect } from 'twilio-video';
import { toast } from 'react-toastify';
import { Button, Row, Col } from 'reactstrap';
// import { FaStar, FaStarHalf } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import arousrLogo from '../../assets/svgs/ArousrNewLogo.svg';
import performerImg from '../../assets/images/performer-img.png';
import stopIcon from '../../assets/images/stopIcon.svg';
import timerIcon from '../../assets/images/timer-icon.svg';
import skipIcon from '../../assets/images/skip-icon.svg';
import customerAvatat from '../../assets/images/customerAvatar.png';
import starWhiteIcon from '../../assets/images/star-white.svg';
import RemoteMedia from './RemoteMedia';
import playSound from '../../utils/playSound';
// import notificationSound from '../../assets/sound/notification.wav';
import callStartSound from '../../assets/sound/callStartSound.mp3';
import { setMin } from './SetTime';
import { timer, removeTimer } from '../../utils/helpers';
import './style.scss';
import LoadSvg from '../../assets/svgs/load.svg';
import interceptor from '../../utils/interceptor';
import transLogo from '../../assets/images/mobile-trans-logo.svg';
import api from '../../actions/api';
import UnderAgeModal from './component/UnderAgeModal';
import crownIcon from '../../assets/svgs/icon-crown.svg';
import CustomerReviews from '../CustomerReviews';

const VideoCall = (props) => {
  const {
    endVideoSessionAction,
    startVideoSessionAction,
    videoSession: { room, token, twilio_room_sid } = {},
    videoSessionLoading,
    history,
    removeToken,
    handleRoulette,
    isHeartBeatApiOn,
    currentTimeConst,
    changeVideoCallData,
    getLastCallData,
    // local redux state manage for roulette
    isRouletteEnable,
    reduxVideoCallData,
    createCustomerReviewFun,
  } = props;
  const [camera, setCamera] = useState(true);
  const [disconnectData, setDisconnectData] = useState(true);
  const [isVidReconScreenEnable, setIsVidReconScreenEnable] = useState(false);
  const [roomObject, setRoomObject] = useState('');
  const [isPremiumChat, setIsPremiumChat] = useState(false);
  const [summarySceenTimer, setSummarySceenTimer] = useState(0);
  const [decrementTimerValue, setDecrementTimerValue] = useState(3);
  const [showUnderAgeModal, setShowUnderAgeModal] = useState(false);
  const [creativeRating, setCreativeRating] = useState();
  const [sexyRating, setSexyRating] = useState();
  const [generousRating, setGenerousRating] = useState();
  const [customerReviewRatingError, setCustomerReviewRatingError] = useState(false);
  const [newReviewObj, setNewReviewObj] = useState(null);
  const performer = useSelector(state => state.performer?.data?.characters);
  // const [isCustomerFavourite, setIsCustomerFavourite] = useState(false);

  const cameraMediaStreamObj = useRef();
  let isVideoEnded = false;
  const performerConnected = false;
  const chatSocket = {};
  const disconnectedFromServer = false;
  const checkBalance = null;
  const {
    customerName: customer,
    custId: customerId,
    id: videoSessionId,
    chatTime: chatlimit,
    // get in notification Roulette call type
    isRouletteCall: isRouletteType,
    customerPic: customerProfilePic,
    isPremiumCustomer,
    customerObjectId,
    voiceCall,
    videoCallRed,
    charObjID,
    isCustomerFavourite: isFavCust,
    // get in notification Video call type
    // callType,
    // isHostAlreadyInFreeCall,
  } = JSON.parse(localStorage.getItem('videoChat')) || {};

  const customerHostReviewObj = localStorage.getItem('customerHostReview') || null;

  const { callType, isHostAvailableForPaidCall, chatTime } = reduxVideoCallData;
  const firstDisConnector = sessionStorage.getItem('connectionDisclouserName');

  useEffect(() => {
    if (customerHostReviewObj !== null) {
      let str = customerHostReviewObj;
      str = str.replace(/'/g, '"');
      const reviewObj = JSON.parse(str);
      const reviewObjVal = Object.values(reviewObj);
      const stateObj = {
        creative: reviewObjVal[0],
        generous: reviewObjVal[1],
        sexy: reviewObjVal[2],
      };
      setNewReviewObj(stateObj);
    }
  }, [customerHostReviewObj]);

  const underAgeModalHandler = () => {
    setShowUnderAgeModal(true);
  };

  const handleCreativeRating = (rate) => {
    setCreativeRating(rate);
  };
  const handleSexyRating = (rate) => {
    setSexyRating(rate);
  };
  const handleGenerousRating = (rate) => {
    setGenerousRating(rate);
  };


  // Close video call on close button clicks only
  const endVideoCallOnButtonClickOnly = () => {
    const dataForDisconnnectedBy = {
      video_session_id: videoSessionId,
      disconnected_by: 1,
    };
    api.put('/cfgvideosession/disconnected_by/', dataForDisconnnectedBy);
  };

  const endVideoSession = (isDisconnectedByCust) => { // removed parameter (isDisconnectedByPerformer = false)
    if (!isVideoEnded) {
      isVideoEnded = true;
      timer(true, 0);
      const formData = new FormData();
      formData.append('action', 'end');
      formData.append('video_session_id', videoSessionId);
      formData.append('device_id', ' ');
      interceptor();
      endVideoSessionAction(formData).then((response) => {
        const { value } = response;
        const { data } = value;
        const {
          performer_revenue_credit,
          duration_seconds, show_review_form,
          customer2cst_contact_objid,
        } = data;
        // if (isDisconnectedByPerformer) {
        //   const dataForDisconnnectedBy = {
        //     video_session_id: videoSessionId,
        //     disconnected_by: 1,
        //   };
        //   api.put('/cfgvideosession/disconnected_by/', dataForDisconnnectedBy);
        // }
        if (isRouletteType === 'None') {
          if (isDisconnectedByCust) {
            console.log('Customer left the session');
            sessionStorage.setItem('connectionDisclouserName', 'customerEnd');
          } else {
            // console.log('inside else part');
            sessionStorage.setItem('connectionDiscloseProp', JSON.stringify({
              rateSession: performer_revenue_credit,
              durationSession: duration_seconds,
              showReviewForm: show_review_form,
              customer2cstContactObjid: customer2cst_contact_objid,
            }));
            sessionStorage.setItem('connectionDisclouserName', room);
          }
        } else {
          sessionStorage.removeItem('connectionDisclouserName');
        }
        const removeTokenData = {
          room,
          token: '',
        };
        if (callType !== '2') {
          changeVideoCallData({
            ...reduxVideoCallData,
            isHostAvailableForPaidCall: true,
          });
        }
        removeToken(removeTokenData);
      }).catch((err) => {
        console.log(err.response.data, 'handle error ============ ');
        const { code } = err.response.data;
        if (code === 'E1000') {
          getLastCallData(videoSessionId).then((responce) => {
            const {
              credit_trans2cfg_credit_trans_objid = null,
              duration_seconds = null,
              show_review_form = null,
              customer2cst_contact_objid,
            } = responce?.value?.data?.results[0];
            const performer_revenue_credit = credit_trans2cfg_credit_trans_objid?.performer_revenue_credit || 0;
            if (isRouletteType === 'None') {
              sessionStorage.setItem('connectionDiscloseProp', JSON.stringify({
                rateSession: performer_revenue_credit,
                durationSession: duration_seconds,
                showReviewForm: show_review_form,
                customer2cstContactObjid: customer2cst_contact_objid,
              }));
              sessionStorage.setItem('connectionDisclouserName', room);
            }
            const removeTokenData = {
              room,
              token: '',
            };
            if (callType !== '2') {
              changeVideoCallData({
                ...reduxVideoCallData,
                isHostAvailableForPaidCall: true,
              });
            }
            removeToken(removeTokenData);
          }).catch(() => {
            console.log('getting error in list api');
          });
        }
      });
      clearInterval(checkBalance);
      if (performerConnected && !disconnectedFromServer) {
        // console.log('------------send delete session data------------------');
        chatSocket.send(JSON.stringify({
          customer_id: customerId,
          video_session_id: videoSessionId,
          type: 'disconnect',
        }));
      }

      if (isRouletteType !== 'None' && isHostAvailableForPaidCall) {
        history.push('/roulette');
      } else {
        setDisconnectData(false);
      }
      localStorage.removeItem('videoChat');
    }
  };

  const turnOffCamera = () => {
    const videoObj = cameraMediaStreamObj?.current?.srcObject;
    if (videoObj?.active) {
      videoObj.getTracks()[0].stop();
    }
  };

  const microPhone = (action) => {
    // console.log('action', action, roomObject);
    if (roomObject) {
      roomObject.localParticipant.audioTracks.forEach((trackId) => {
        if (action === 'mute') {
          trackId.track.disable();
        } else {
          trackId.track.enable();
        }
      });
    }
  };

  const trackSubscribed = (div = {}, track) => {
    // eslint-disable-next-line no-unused-expressions
    if (track.kind !== 'data' && div) {
      div.appendChild(track.attach());
    } else {
      track.on('message', (message) => {
        const customerMsg = JSON.parse(message);
        if (customerMsg.type === 'premium') {
          setIsPremiumChat(true);
        }
        if (customerMsg.type === 'timer') {
          removeTimer();
          timer(false, customerMsg.content);
        } else {
          // playSound(notificationSound);
          toast(() => (
            <Row>
              <Col md="2">
                <img className="arousr_logo" src={arousrLogo} alt="logo" />
              </Col>
              <Col md="10" className="d-flex p-2">
                <span className="c-888">{customerMsg.content}</span>
              </Col>
            </Row>
          ), {
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true,
          });
        }
      });
    }
  };

  const trackUnsubscribed = (track) => {
    if (track.kind !== 'data') {
      track.detach();
    }
    // track.detach().forEach(element => element.remove());
  };

  const participantConnected = (participant) => {
    const div = document.getElementById('remote-media-div');
    participant.on('trackSubscribed', track => trackSubscribed(div, track));
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });
  };

  const participantDisconnected = (participant) => {
    console.log('Participant "%s" disconnected', participant.identity);
    turnOffCamera();
    // localStorage.removeItem('videoChat');
    // if (isRouletteType !== 'None') {
    //   sessionStorage.removeItem('connectionDisclouserName');
    // } else {
    //   sessionStorage.setItem('connectionDisclouserName', room);
    // }
    console.log('end video session in participantDisconnected ======');
    // const isDisconnectedByPerformer = false;
    endVideoSession();
  };

  // const voiceCallPermissionCheck = async () => {
  //   try {
  //     const audioStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
  //     audioStream.getTracks().forEach(track => track.stop());
  //     setCamera(true);
  //   } catch (error) {
  //     setCamera(false);
  //     console.error('Error granting microphone permission:', error);
  //   }
  // };

  // const videoCallPermissionCheck = async () => {
  //   try {
  //     const videoStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  //     console.log(videoStream);
  //     videoStream.getTracks().forEach(track => track.stop());
  //     setCamera(true);
  //   } catch (error) {
  //     setCamera(false);
  //     console.error('Error granting microphone permission:', error);
  //   }
  // };

  // if (voiceCall) {
  //   voiceCallPermissionCheck();
  // } else {
  //   videoCallPermissionCheck();
  // }

  // if (videoCallRed) {
  //   videoCallPermissionCheck();
  // }

  if (voiceCall && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .then(() => {
        setCamera(true);
      })
      .catch((error) => {
        console.error(error);
        setCamera(false);
      });
  }

  // eslint-disable-next-line no-lonely-if
  if (videoCallRed && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    const video = document.querySelector('#videoElement');
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then((streamData) => {
        if (video) {
          video.srcObject = streamData;
        }
        setCamera(true);
      })
      .catch((error) => {
        console.error(error);
        setCamera(false);
      });
  }

  useEffect(() => {
    if (videoSessionId) {
      const acceptVideoSession = {
        action: 'accept',
        video_session_id: videoSessionId,
        device_id: ' ',
        status: 'accepted',
        character_channel_type: 'BROWSER',
      };
      interceptor();
      startVideoSessionAction(acceptVideoSession).then((response) => {
        console.log('response', response);
        playSound(callStartSound);
        if (currentTimeConst) {
          clearInterval(currentTimeConst);
          const rouletteData = {
            isRouletteEnable,
            isHeartBeatApiOn,
            currentTimeConst: 0,
          };
          handleRoulette(rouletteData);
        }
      }).catch((error) => {
        // console.log('----------error---------', error, error.response.data.result);
        if (error.response && (error.response.status === 400)) {
          toast.error('Customer has already ended the session.');
          setTimeout(
            () => {
              const ww = window.open(window.location, '_self');
              ww.close();
            }, 3000,
          );
        }
      });
    }
    if (isRouletteType !== 'None') {
      sessionStorage.removeItem('connectionDisclouserName');
      sessionStorage.removeItem('connectionDiscloseProp');
    }
  }, [videoSessionId]);
  const getReconnectScreen = () => {
    if (isVidReconScreenEnable) {
      return (
        <div className="loading-screen">
          <span className="call-loading-text">
            <h3>
              {`Cutomer has disconnected from the call, attempting to restablishing connection with ${customer}`}
            </h3>
            <p>Please stand by</p>
            <img src={LoadSvg} alt="logosvg" />
          </span>
        </div>
      );
    }
    return '';
  };

  useEffect(() => {
    let connectOptions = {};
    if (voiceCall) {
      connectOptions = {
        audio: true,
        video: false,
      };
    } else {
      connectOptions = {
        audio: true,
        video: true,
      };
    }
    if (token) {
      connect(token, connectOptions, { name: room }).then((roomI) => {
        if (twilio_room_sid !== roomI.sid) {
          // console.log('Twillio SID Not matched');
          const isDisconnectedByCust = true;
          endVideoSession(isDisconnectedByCust);
          return;
        }

        setRoomObject(roomI);
        timer(false, chatlimit);
        if (voiceCall) {
          roomI.localParticipant.videoTracks.forEach((trackId) => {
            trackId.track.disable();
          });
          // roomI.localParticipant.audioTracks.forEach((trackId) => {
          //   trackId.track.enable();
          // });
        }
        roomI.participants.forEach(participantConnected);
        roomI.on('participantConnected', participantConnected);

        roomI.on('participantDisconnected', participantDisconnected);
        roomI.once('disconnected', (roomData, error) => {
          localStorage.removeItem('isVideoVoiceCall');
          console.log(roomData, '|||||||', error, 'getting error');
          // endVideoCallOnButtonClickOnly();
          // const isDisconnectedByPerformer = true;
          roomI.participants.forEach(() => endVideoSession());
        });
        roomI.on('reconnecting', (error) => {
          if (error.code === 53001) {
            console.log('Reconnecting your signaling connection!', error.message);
          } else if (error.code === 53405) {
            console.log('Reconnecting your media connection!', error.message);
          }
          setIsVidReconScreenEnable(true);
        });

        roomI.on('reconnected', () => {
          setIsVidReconScreenEnable(false);
        });
      }, (error = {}) => {
        console.error(`Unable to connect to Room: ${error.message}`);
      });
    }
    return () => {
      removeTimer();
      if (history?.action === 'POP' && !isVideoEnded && isRouletteEnable) {
        console.log('in token effect return in back button code');
        // const isDisconnectedByPerformer = false;
        endVideoSession();
      }
    };
  }, [token]);

  useEffect(() => {
    if (callType === '3') {
      removeTimer();
      timer(false, chatTime);
    }
  }, [callType]);

  const closeVideoCall = () => {
    localStorage.removeItem('isVideoVoiceCall');
    turnOffCamera();
    if (roomObject) {
      setTimeout(() => {
        endVideoCallOnButtonClickOnly();
      }, 2000);
      roomObject.disconnect();
    }
    if (!firstDisConnector && isRouletteType === 'None') {
      const roomName = room || 'You have';
      sessionStorage.setItem('connectionDisclouserName', roomName);
    }
  };


  useEffect(() => {
    window.addEventListener('beforeunload', closeVideoCall);
    return () => window.removeEventListener('beforeunload', closeVideoCall);
  }, []);

  const decrementValue = (maxNo) => {
    if (maxNo <= 0) {
      return;
    }
    const nNum = maxNo - 1;
    setDecrementTimerValue(maxNo);
    setTimeout(() => decrementValue(nNum), 1000);
  };

  useEffect(() => {
    if (!disconnectData) {
      if (isRouletteEnable && summarySceenTimer === 0) {
        const removeSummaryScreenTimeout = setTimeout(() => history.push('/roulette'), 4000);
        decrementValue(3);
        setSummarySceenTimer(removeSummaryScreenTimeout);
      }
    }
  }, [disconnectData]);

  // const renderRating = rating => (
  //   <>
  //     {new Array(5).fill(undefined).map((elem, index) => {
  //       const number = index + 0.5;
  //       if (rating === number) {
  //         return <FaStarHalf style={{ color: number <= rating ? '#ffae00' : '#b8b7b7' }} />;
  //       }
  //       // eslint-disable-next-line react/no-array-index-key
  //       return <FaStar key={index} style={{ color: index + 1 <= rating ? '#ffae00' : '#b8b7b7' }} />;
  //     })}
  //   </>
  // );
  const handleSubmitReview = () => {
    let customer2cstContactObjidData = '';
    if (JSON.parse(sessionStorage.getItem('connectionDiscloseProp'))) {
      const { customer2cstContactObjid } = JSON.parse(sessionStorage.getItem('connectionDiscloseProp'));
      customer2cstContactObjidData = customer2cstContactObjid;
    }
    if (creativeRating !== undefined && sexyRating !== undefined && generousRating !== undefined) {
      // console.log('creative:', creativeRating, 'sexy', sexyRating, 'generous', generousRating);
      const formData = new FormData();
      formData.append('cst_review2cst_contact', customer2cstContactObjidData);
      formData.append('creative', creativeRating);
      formData.append('generous', generousRating);
      formData.append('sexy', sexyRating);
      formData.append('char_id', performer[0].objid);
      createCustomerReviewFun(formData);
      setCustomerReviewRatingError(false);
      setTimeout(
        () => {
          const ww = window.open(window.location, '_self');
          ww.close();
        }, 1000,
      );
    } else {
      setCustomerReviewRatingError(true);
    }
  };

  const renderButtons = () => {
    if (creativeRating !== undefined || sexyRating !== undefined || generousRating !== undefined) {
      return (
        <Button
          onClick={() => handleSubmitReview()}
          className="close-chat-button"
        >
          Close Session
        </Button>
      );
    }

    return (
      <Button
        onClick={() => {
          setTimeout(
            () => {
              const ww = window.open(window.location, '_self');
              ww.close();
            }, 1000,
          );
        }}
        className="close-chat-button"
      >
        Close Session
      </Button>
    );
  };


  const getCallEndingScreen = () => {
    let rateSessionData = 0;
    let durationSessionData = 0;
    let showReviewFormData = false;
    const roomNewName = sessionStorage.getItem('connectionDisclouserName');
    if (JSON.parse(sessionStorage.getItem('connectionDiscloseProp'))) {
      const { rateSession, durationSession, showReviewForm } = JSON.parse(sessionStorage.getItem('connectionDiscloseProp'));
      rateSessionData = rateSession && parseInt(rateSession, 10).toFixed(2);
      durationSessionData = durationSession;
      showReviewFormData = showReviewForm;
    }
    return (
      <div className="arousr_logo_big">
        {roomNewName === 'customerEnd' ? (
          <>
            <img className="arousr_logo" src={arousrLogo} alt="logo" />
            <span className="call-loading-text">
              <h3>
                Customer left the session
              </h3>
              <Button
                onClick={() => {
                  setTimeout(
                    () => {
                      const ww = window.open(window.location, '_self');
                      ww.close();
                    }, 1000,
                  );
                }}
                className="close-chat-button"
              >
                Close Video Chat
              </Button>
            </span>
          </>
        ) : (
          <>
            <img className="arousr_logo" src={arousrLogo} alt="logo" />
            <span className="call-loading-text">
              <h3>
                {room ? `${room} left the session` : 'You left the session'}
              </h3>
              <div>
                {durationSessionData && rateSessionData ? (
                  <>
                    <div className="d-flex mt-20">
                      <div className="duration-info">
                        <div> Duration of session </div>
                        <div>
                          {`${setMin(durationSessionData || 0)} min`}
                        </div>
                      </div>
                      <div className="earned-info">
                        <div> Credits you earned </div>
                        <div>
                          {`${rateSessionData} credits`}
                        </div>
                      </div>
                    </div>
                    {showReviewFormData ? (
                      <div className="customer_reviews">
                        <h4>Rate Customer</h4>
                        <ul>
                          <li>
                            <span>
                            Creativity :
                            </span>
                            <span><CustomerReviews value={creativeRating} newRatingHandlerFun={handleCreativeRating} /></span>
                          </li>
                          <li>
                            <span>
                            Sexiness :
                            </span>
                            <span><CustomerReviews value={sexyRating} newRatingHandlerFun={handleSexyRating} /></span>
                          </li>
                          <li>
                            <span>
                            Generosity :
                            </span>
                            <span><CustomerReviews value={generousRating} newRatingHandlerFun={handleGenerousRating} /></span>
                          </li>
                        </ul>
                      </div>
                    ) : ''}
                  </>
                ) : (
                  <div>
                    Your earned credits will be reflected in your
                    <br />
                    balance within 5 mins.
                  </div>
                )
                }
              </div>
              {customerReviewRatingError && <p className="rating-error">All Ratings are mandatory!</p>}
              {
                isRouletteEnable ? (
                  <>
                    <Button
                      onClick={() => {
                        clearInterval(summarySceenTimer);
                        history.push('/roulette');
                      }}
                      className="close-chat-button"
                    >
                      Continue on roulette
                    </Button>
                    <div>{`Redirecting in ${decrementTimerValue}`}</div>
                  </>
                ) : (
                  // <Button
                  //   onClick={() => {
                  //     setTimeout(
                  //       () => {
                  //         const ww = window.open(window.location, '_self');
                  //         ww.close();
                  //       }, 1000,
                  //     );
                  //   }}
                  //   className="close-chat-button"
                  // >
                  //   Close Video Chat
                  // </Button>
                  renderButtons()
                )
              }
            </span>
          </>
        )}
      </div>
    );
  };

  const favUnFavHandlerFun = (isFavUnFav) => {
    console.log('isFavUnFav', isFavUnFav);
    if (isFavUnFav) {
      const url = '/api/xrefcustomercharacter/create/';
      const formData = new FormData();
      formData.append('perf_character_objid', charObjID);
      formData.append('cst_contact_objid', customerObjectId);
      formData.append('type', 'favorite_customer');
      api.post(url, formData).then((response) => {
        console.log('response', response);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      const url = '/api/removecustomercharacterrelation/';
      const formData = new FormData();
      formData.append('char_objid', charObjID);
      formData.append('cust_objid', customerObjectId);
      formData.append('type', 'favorite_customer');
      api.post(url, formData).then((response) => {
        console.log('response', response);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const changePremiumAndFreeCallUI = () => {
    if (isRouletteType !== 'None' && !isPremiumChat) {
      return (
        <div className="skip_Call_wrap skip-btn-wrap">
          <div className="skip-wrap">
            <button type="button" onClick={closeVideoCall} className="skip-btn">
              <img className="skip-img" src={skipIcon} alt="skip-icon" />
              <p>SKIP</p>
            </button>
            <button type="button" className="report_underage_btn" onClick={underAgeModalHandler}>
              Report Underage
            </button>
          </div>
          <div className="callWrap ">
            <div className="freeCall">
              <p>FREE CALL</p>
            </div>
          </div>
        </div>
      );
    }
    if (isRouletteType !== 'None' && isPremiumChat) {
      return (
        <div className="skip_Call_wrap">
          <div className="callWrap">
            <div className="paidCall">
              <img src={starWhiteIcon} alt="star" />
              <p>PAID CALL</p>
            </div>
            <button type="button" className="report_underage_btn" onClick={underAgeModalHandler}>
              Report Underage
            </button>
            <div className="payment-info-text">*Earned credits will appear in 5 mins.</div>
          </div>
        </div>
      );
    }
    return '';
  };

  const normalVideoCallBtn = () => {
    if (isRouletteType === 'None') {
      return (
        <button type="button" className="report_underage_btn normal-call-btn" onClick={underAgeModalHandler}>
          Report Underage
        </button>
      );
    }
    return '';
  };
  const getVideoChat = () => {
    if (camera) {
      return (
        <>
          {isVidReconScreenEnable ? getReconnectScreen() : '' }
          {changePremiumAndFreeCallUI()}
          <div className="performer-screen">
            <RemoteMedia
              microPhone={microPhone}
              voiceCall={voiceCall}
              favUnFavHandlerFun={favUnFavHandlerFun}
              isCustomerFavourite={isFavCust}
            />
            {voiceCall ? (
              <div className="cust-voice-media">
                <div className="img-with-crown">
                  {customerProfilePic !== 'None' ? (
                    <img className="profile-img" src={customerProfilePic} alt="profile-img" />
                  ) : <img className="profile-img" src={customerAvatat} alt="profile-img" />
                  }
                  {isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
                </div>
              </div>
            ) : (
              <video
                id="videoElement"
                className="videoElement"
                ref={cameraMediaStreamObj}
                autoPlay
              />
            )}
          </div>
        </>
      );
    }

    if (voiceCall) {
      return (
        <div className="arousr_logo_big">
          <img className="arousr_logo" src={arousrLogo} alt="logo" />
          <p className="no-camera">Microphone permission not allowed.</p>
        </div>
      );
    }
    return (
      <div className="arousr_logo_big">
        <img className="arousr_logo" src={arousrLogo} alt="logo" />
        <p className="no-camera"> Camera & Microphone permission not allowed.</p>
      </div>
    );

    // if (videoCallRed) {
    //   return (
    //     <div className="arousr_logo_big">
    //       <img className="arousr_logo" src={arousrLogo} alt="logo" />
    //       <p className="no-camera"> Camera & Microphone permission not allowed.</p>
    //     </div>
    //   );
    // }
    // return false;
  };

  // Report Under age API call
  const checkUnderAgeAPICall = () => {
    const url = '/api/cstcontactflags/create/';
    const formData = new FormData();

    formData.append('flag_name', 'VERIFY_WATCH');
    formData.append('reason', 'UNDERAGE');
    formData.append('contact2cst_contact', customerObjectId);
    formData.append('perf_character', charObjID);

    api.post(url, formData)
      .then((response) => {
        if (response?.data?.reason === 'UNDERAGE') {
          // console.log('value', response?.data?.reason);
          closeVideoCall();
        }
        if (response?.data?.result === 'Already Exists') {
          closeVideoCall();
        }
      })
      .catch(error => console.log(error));
  };

  const renderUnderAgePopup = () => (
    <UnderAgeModal
      header=""
      body=""
      buttonText="OK"
      onClick={checkUnderAgeAPICall}
      open={showUnderAgeModal}
      toggle={() => setShowUnderAgeModal(false)}
    />
  );

  return (
    <div className="video-call">
      {renderUnderAgePopup()}
      <div className="d-flex justify-content-between chat-header">
        {
          videoSessionLoading && customer !== undefined && (
            <>
              <div className="d-flex align-items-center">
                <img className="arousr_text_logo profile-img" src={performerImg} alt="profile-img" />
                <p className="performer-name">Performer</p>

              </div>
              <div className="loading-screen">
                <span className="call-loading-text">
                  <h3>
                    {`Establishing connection with ${customer}`}
                  </h3>
                  <p>Please stand by</p>
                  <img src={LoadSvg} alt="logosvg" />
                </span>
              </div>
            </>
          )}
        {!firstDisConnector && (videoSessionId) ? (
          <>
            <div className="d-flex align-items-center cust-img-rating-wrap">
              {!voiceCall && (
                <div className="img-with-crown">
                  {customerProfilePic !== 'None' ? (
                    <img className="profile-img" src={customerProfilePic} alt="profile-img" />
                  ) : <img className="profile-img" src={customerAvatat} alt="profile-img" />
                  }
                  {isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
                </div>
              )}
              <div className="cust-name-rating-wrap">
                <p className="performer-name">{customer}</p>
                {newReviewObj ? (
                  <div className="chat-videocall-customer-review">
                    <div className="chat-reviews">
                      <div className="bar-seprate-line-wrap videocall">
                        <span className="bar-seprate-line bar-line-1" />
                        <span className="bar-seprate-line bar-line-2" />
                        <span className="bar-seprate-line bar-line-3" />
                        <span className="bar-seprate-line bar-line-4" />
                      </div>
                      <SemiCircleProgressBar
                        percentage={newReviewObj.creative * 20}
                        strokeWidth={7}
                        diameter={50}
                        stroke="#ff7167"
                        background="#3E3E3E"
                      />
                      <div className="title_container">
                        <span className="rating_number">
                          {newReviewObj.creative}
                        </span>
                        <span className="rating_title">Creativity</span>
                      </div>
                    </div>
                    <div className="chat-reviews">
                      <div className="bar-seprate-line-wrap videocall">
                        <span className="bar-seprate-line bar-line-1" />
                        <span className="bar-seprate-line bar-line-2" />
                        <span className="bar-seprate-line bar-line-3" />
                        <span className="bar-seprate-line bar-line-4" />
                      </div>
                      <SemiCircleProgressBar
                        percentage={newReviewObj.sexy * 20}
                        strokeWidth={7}
                        diameter={50}
                        stroke="#ff7167"
                        background="#3E3E3E"
                      />
                      <div className="title_container">
                        <span className="rating_number">
                          {newReviewObj.sexy}
                        </span>
                        <span className="rating_title">Sexiness</span>
                      </div>
                    </div>
                    <div className="chat-reviews">
                      <div className="bar-seprate-line-wrap videocall creativity">
                        <span className="bar-seprate-line bar-line-1" />
                        <span className="bar-seprate-line bar-line-2" />
                        <span className="bar-seprate-line bar-line-3" />
                        <span className="bar-seprate-line bar-line-4" />
                      </div>
                      <SemiCircleProgressBar
                        percentage={newReviewObj.generous * 20}
                        strokeWidth={7}
                        diameter={50}
                        stroke="#ff7167"
                        background="#3E3E3E"
                      />
                      <div className="title_container">
                        <span className="rating_number">
                          {newReviewObj.creative}
                        </span>
                        <span className="rating_title">Generosity</span>
                      </div>
                    </div>

                    {/* <div className="chat-reviews">
                      <span className="rating-title">Creative : </span>
                      <span>
                        {renderRating(newReviewObj.creative)}
                      </span>
                    </div>
                    <div className="chat-reviews">
                      <span className="rating-title">Sexy : </span>
                      <span>
                        {renderRating(newReviewObj.sexy)}
                      </span>
                    </div>
                    <div className="chat-reviews">
                      <span className="rating-title">Generous : </span>
                      <span>
                        {renderRating(newReviewObj.generous)}
                      </span>
                    </div> */}
                  </div>
                ) : ''}
              </div>
            </div>
            <a className="transparentLogo" to="/dashboard"><img src={transLogo} alt="LOGO" /></a>
            <div className="counterWrap">
              {/* disable-eslint-next-line */}
              <button className="close-btn" type="button" onClick={closeVideoCall}>
                <img className="stop-icon" src={stopIcon} alt="cross-icon" />
              </button>
              <div className="timer-wrap">
                <img className="timer-icon" src={timerIcon} alt="timer-icon" />
                <span id="counter" />
              </div>
            </div>
            {normalVideoCallBtn()}
          </>
        ) : ''}
      </div>

      {!firstDisConnector && disconnectData && videoSessionId ? getVideoChat() : getCallEndingScreen()}
    </div>
  );
};

VideoCall.defaultProps = {
  videoSession: {},
  endSession: {
    status: '',
    duration_seconds: 0,
  },
  videoSessionLoading: false,
  isRouletteEnable: false,
  isHeartBeatApiOn: 0,
  currentTimeConst: 0,
  reduxVideoCallData: {},
};

VideoCall.propTypes = {
  startVideoSessionAction: PropTypes.func.isRequired,
  endVideoSessionAction: PropTypes.func.isRequired,
  isRouletteEnable: PropTypes.bool,
  reduxVideoCallData: PropTypes.instanceOf(object),
  removeToken: PropTypes.func.isRequired,
  handleRoulette: PropTypes.func.isRequired,
  changeVideoCallData: PropTypes.func.isRequired,
  isHeartBeatApiOn: PropTypes.number,
  currentTimeConst: PropTypes.number,
  videoSession: PropTypes.shape({
    room: PropTypes.string,
    token: PropTypes.string,
  }),
  endSession: PropTypes.shape({
    status: PropTypes.string,
    duration_seconds: PropTypes.number,
  }),
  videoSessionLoading: PropTypes.bool,
  history: PropTypes.instanceOf(object).isRequired,
  getLastCallData: PropTypes.func.isRequired,
  createCustomerReviewFun: PropTypes.func.isRequired,
};

export default VideoCall;
