/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button, Modal, ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import './style.scss';
import Lightbox from 'react-image-lightbox';
import MediaVideoModal from './MediaVideoModal';

const MediaModal = (props) => {
  const {
    handleChatMediaModal,
    getChatMediaVideos,
    getChatMediaPhotos,
    isOpen,
    setIsMediaModal,
    setSharedPhotos,
    setSharedVideos,
  } = props;

  const [activeTab, setActiveTab] = React.useState('image-media');
  const [modal, setModal] = useState(true);
  const [open, setOpen] = useState(true);
  const [chatMediaPhotosGallery, setChatMediaPhotosGallery] = useState([]);
  const [chatMediaVideosGallery, setChatMediaVideosGallery] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [selectedVideo, setSelectedVideo] = useState('');
  const [mainSrcPicCon, setmainSrcPicCon] = useState('');
  const [showVideoModal, setShowVideoModal] = useState(false);

  useEffect(() => {
    setModal(isOpen);
  }, []);


  const renderVideoModal = (video) => {
    if (showVideoModal) {
      return (
        <MediaVideoModal
          header=""
          body=""
          buttonText="OK"
      // footer="CANCEL"
      // footerLink={() => setAfterUpdateProfilePicture(false)}
          open={open}
      // onClick={resetBackgroundPicPanel}
          videoId={video.video_id}
          setOpen={setOpen}
        />
      );
    }
    return '';
  };

  const toggle = () => {
    // handleChatMediaModal();
    setModal(!modal);
    setIsMediaModal(false);
    setSharedPhotos([]);
    setSharedVideos([]);
  };

  const selectChatMediaPhoto = (photoUrl) => {
    setSelectedPhoto(photoUrl);
  };

  const selectChatMediaVideo = (videoObj) => {
    setSelectedVideo(videoObj);
    setShowVideoModal(true);
    setOpen(true);
  };


  useEffect(() => {
    if (getChatMediaPhotos && getChatMediaPhotos.length) {
      const photoGallery = getChatMediaPhotos.map(photo => (
        <div key={`privateProduct${photo.product_id}`} className="chatMediaPhoto">
          <button type="button" onClick={() => selectChatMediaPhoto(photo)}>
            <img src={photo.product_thumb_url} alt={photo.product_name} height="50" role="presentation" width="50" onClick={() => setmainSrcPicCon(photo.product_thumb_url)} />
          </button>
        </div>
      ));
      setChatMediaPhotosGallery(photoGallery);
    }
  }, [getChatMediaPhotos]);

  const closePhoto = () => {
    setmainSrcPicCon('');
  };


  useEffect(() => {
    if (getChatMediaVideos && getChatMediaVideos.length) {
      const videoGallery = getChatMediaVideos.filter(media => media.type === 'chat_video').map(video => (
        <div key={`privateProduct${video.product_id}`} className="chatMediaPhoto" role="presentation">
          <button type="button" onClick={() => selectChatMediaVideo(video)}>
            <img src={video.product_thumb_url} alt={video.product_name} height="50" width="50" />
            <div className="video_play_icon">
              <span className="play_back_circle" />
              <i className="fa fa-play" aria-hidden="true" />
            </div>
          </button>
        </div>
      ));
      setChatMediaVideosGallery(videoGallery);
    }
  }, [getChatMediaVideos]);


  return (
    <div>
      {renderVideoModal(selectedVideo)}
      <Modal isOpen={modal} toggle={toggle} className="chatMediaModal chatMediaDialog">
        <ModalHeader toggle={toggle}>Shared Media</ModalHeader>
        <ModalBody>
          <div className="chat-media-tabs-menu">
            <Nav tabs fill>
              <NavItem>
                <NavLink active={activeTab === 'image-media'} onClick={() => setActiveTab('image-media')}>
                  Photo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={activeTab === 'video-media'} onClick={() => setActiveTab('video-media')}>
                  Video
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="image-media">
                {chatMediaPhotosGallery && chatMediaPhotosGallery.length ? (
                  <section className="chatMedia-gallery-grid">
                    {chatMediaPhotosGallery}
                  </section>

                ) : (
                  <div className="text-center">No Photos Found!</div>
                )
                }
              </TabPane>
              <TabPane tabId="video-media">
                {chatMediaVideosGallery && chatMediaVideosGallery.length ? (
                  <section className="chatMedia-gallery-grid">
                    {chatMediaVideosGallery}
                  </section>
                ) : (
                  <div className="text-center">No Videos Found!</div>
                )
                }
              </TabPane>
            </TabContent>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button className="sendBtn" color="primary" disabled={!selectedPhoto}>Send</Button>
        </ModalFooter> */}
        {
        mainSrcPicCon && (
          <Lightbox
            ClassName="Lightbox"
            mainSrc={mainSrcPicCon}
            onCloseRequest={closePhoto}
          />
        )
      }
      </Modal>
    </div>
  );
};

MediaModal.defaultProps = {
  isOpen: false,
  getChatMediaPhotos: [],
  getChatMediaVideos: [],
  setIsMediaModal: () => {},
  setSharedPhotos: () => {},
  setSharedVideos: () => {},
};

MediaModal.propTypes = {
  handleChatMediaModal: PropTypes.func.isRequired,
  getChatMediaPhotos: PropTypes.array,
  getChatMediaVideos: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsMediaModal: PropTypes.func,
  setSharedPhotos: PropTypes.func,
  setSharedVideos: PropTypes.func,

};

export default MediaModal;
