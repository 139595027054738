/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Button, Col, Form, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner,
} from 'reactstrap';
import './style.scss';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import api from '../../../../actions/api';

const ResetPassword = () => {
  const [modal, setModal] = useState(false);
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    confirmPasswordMismatch: '',
  });
  const [serverMsg, setServerMsg] = useState({ error: '', success: '' });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUser = useSelector(state => state?.login?.data);

  const toggle = () => {
    setModal(!modal);
    setValidationErrors({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      confirmPasswordMismatch: '',
    });
    setServerMsg('');
    setPasswords({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };
  const validatePassword = (password) => {
    // Password must be 6-15 characters long, and contain one uppercase and one lowercase character
    const regex = /^(?=.*[a-z])(?=.*[A-Z]).{6,15}$/;
    return regex.test(password);
  };

  const validateForm = () => {
    const newErrors = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      confirmPasswordMismatch: '',
    };

    if (!passwords.currentPassword) {
      newErrors.currentPassword = 'This field cannot be empty.';
    }

    if (!passwords.newPassword) {
      newErrors.newPassword = 'This field cannot be empty.';
    } else if (passwords.currentPassword === passwords.newPassword) {
      newErrors.confirmPasswordMismatch = 'The new and old passwords should not be the same.';
    } else if (!validatePassword(passwords.newPassword)) {
      newErrors.newPassword = 'Password must be 6-15 characters long, and contain one uppercase and one lowercase character.';
    }

    if (!passwords.confirmPassword) {
      newErrors.confirmPassword = 'This field cannot be empty.';
    } else if (passwords.newPassword !== passwords.confirmPassword) {
      newErrors.confirmPasswordMismatch = 'New Password and Confirm Password do not match.';
    }

    setValidationErrors(newErrors);

    return Object.values(newErrors).every(error => error === '');
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setPasswords(prevPasswords => ({
      ...prevPasswords,
      [name]: value,
    }));

    if (name === 'currentPassword' || name === 'newPassword') {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        [name]: value ? '' : 'This field cannot be empty.',
      }));
    } else if (name === 'confirmPassword') {
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        confirmPassword: value ? '' : 'This field cannot be empty.',
        confirmPasswordMismatch: passwords.newPassword === value ? '' : 'New Password and Confirm Password do not match.',
      }));
    }

    if (name === 'newPassword') {
      if (value && !validatePassword(value)) {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          newPassword: 'Password must be 6-15 characters long, and contain one uppercase and one lowercase character.',
        }));
      }
      if (value && value === passwords.currentPassword) {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          newPassword: 'The new and old passwords should not be the same.',
        }));
      } else {
        setValidationErrors(prevErrors => ({
          ...prevErrors,
          newPassword: '',
        }));
      }
    }
  };

  const toCapitalize = message => message.split(' ').map(el => el.charAt(0).toUpperCase() + el.slice(1)).join(' ');

  const renderButton = () => (
    <Row className="Password-reset">
      <Col className="d-flex">
        <Button type="button" onClick={toggle} color="primary" className="doneBtn text-white mt-3">Reset Password</Button>
      </Col>
    </Row>
  );

  const handleSubmit = async () => {
    const isValid = validateForm();
    if (isValid) {
      try {
        setIsLoading(true);
        const { data } = await api.post('/api/cfgauth/changepassword/', {
          old_password: passwords.currentPassword,
          password: passwords.newPassword,
          cfg_auth_objid: loggedInUser?.objid,
        });
        setServerMsg({ success: toCapitalize(toCapitalize(data.message)), error: '' });
        setTimeout(() => {
          toggle();
        }, 3000);
        setIsLoading(false);
      } catch (error) {
        const { message } = error.response.data;
        setIsLoading(false);
        setServerMsg({ error: toCapitalize(message), success: '' });
      }
    }
  };

  return (
    <div className="border-top border-dark mt-4">
      <h2 className="mt-3" style={{ lineHeight: 1 }}>
        <span className="headTitle">Password Reset</span>
      </h2>
      {renderButton()}
      <Modal className="EditInfo-Modal modal-center" isOpen={modal} backdrop={false} toggle={toggle}>
        <ModalHeader toggle={toggle} className="chat-modal-header" />
        <ModalBody className="chat-modal-body">
          <Form className="mt-3">
            <div className="InputContainer">
              <Label htmlFor="currentPassword">
              Current Password
              </Label>
              <InputGroup>
                <Input
                  id="currentPassword"
                  name="currentPassword"
                  type={showCurrentPassword ? 'text' : 'password'}
                // className="form-control credit-field"
                  value={passwords.currentPassword}
                  onChange={onChangeHandler}
                  placeholder="Enter Current Password"
                />
                <div className="eye-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                  {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </InputGroup>
              {validationErrors.currentPassword && (
              <div className="validation-error">{validationErrors.currentPassword}</div>
              )}
            </div>

            <div className="InputContainer">
              <Label htmlFor="newPassword">
              New Password
              </Label>
              <InputGroup>
                <input
                  id="newPassword"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  className="form-control credit-field"
                  value={passwords.newPassword}
                  onChange={onChangeHandler}
                  placeholder="Enter New Password"
                />
                <div className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </InputGroup>

              {validationErrors.newPassword && (
              <div className="validation-error">{validationErrors.newPassword}</div>
              )}
            </div>
            <div className="InputContainer mb-0">
              <Label htmlFor="confirmPassword">
              Confirm Password
              </Label>
              <InputGroup>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="form-control credit-field"
                  value={passwords.confirmPassword}
                  onChange={onChangeHandler}
                  placeholder="Confirm New Password"
                />
                <div className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </InputGroup>
              {validationErrors.confirmPassword && (
              <div className="validation-error">{validationErrors.confirmPassword}</div>
              )}
              {validationErrors.confirmPasswordMismatch && (
              <div className="validation-error">{validationErrors.confirmPasswordMismatch}</div>
              )}
            </div>
            { serverMsg.error
            && (
            // <Alert color="danger" style={{ margin: '30px 0px 20px 0px' }}>
            //   {serverMsg.error}
            // </Alert>

              <div className="validation-server-error">{serverMsg.error}</div>

            )
          }
            { serverMsg.success
            && (
              // <Alert color="success" style={{ margin: '30px 0px 20px 0px' }}>
              //   {serverMsg.success}
              // </Alert>
              <div className="validation-server-success">{serverMsg.success}</div>
            )
          }
            <Button type="button" onClick={handleSubmit} color="primary" className="doneBtn text-white">
              {!isLoading ? 'Reset Password' : 'Resetting...'}
              {isLoading ? (
                <Spinner
                  style={{ width: '0.7rem', height: '0.7rem' }}
                  type="grow"
                  color="dark"
                />
              ) : null }
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ResetPassword;
