/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  CardHeader,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Button,
  Input,
} from 'reactstrap';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { renderReactSelectField, renderField } from '../../utils/formUtils';
import './style.scss';
import LOCATIONS, { PREFERENCES, US_ACCOUNT_TYPES } from './constants';
import {
  required, exactLen9, exactLen3, minLen6, minLen7, minLen9, maxLen12,
  maxLen18, maxLen34, exactLen5, maxLen11,
} from '../../utils/formUtils/validator';
import api from '../../actions/api';
import PaymentMethodsSelection from './PaymentMethodsSelection';
import CosmoPayment from './CosmoPayment';

const PayoutSettings = (props) => {
  const {
    handleSubmit, postPayoutDetails, perf2cstContactId,
    payoutSettings, initialize, payoutSettingsForm, change,
  } = props;
  const { payoutDetails, isLoading } = payoutSettings;

  const [selectedLocation, setSelectedLocation] = useState(null);
  // state for dynamic preference option based on location
  const [preferenceOptions, setPreferenceOptions] = useState([]);
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(US_ACCOUNT_TYPES[0]);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countriesOptions, setContriesOptions] = useState([]);
  const [selectedCountryId, setselectedCountryId] = useState();
  const [stateOtherOptions, setStateOtherOptions] = useState([]);
  const [selectedOthersStates, setSelectedOthersStates] = useState(null);
  const [paymentOption, setPaymentOption] = useState(payoutDetails?.results?.length ? payoutDetails?.results[0]?.selectedPaymentMethod : '');
  const [userSetSelectedPaymentOption, setUserSetSelectedPaymentOption] = useState('');
  const [SAN, setSAN] = useState('');
  const ToastID = useRef(paymentOption);


  // useEffect(() => {
  //   setContriesOptions([]);
  // }, []);

  useEffect(() => {
    setStateOtherOptions([]);
  }, []);

  useEffect(() => {
    setStateOptions([]);
  }, []);

  // Get all countries from API
  const getCountriesList = (otherCountries) => {
    if (otherCountries?.length) {
      const modContryOptions = otherCountries.map(item => ({
        value: item.objid,
        label: item.country_name,
      }));
      setContriesOptions(modContryOptions || []);
    } else {
      api.get('/api/cfgcountry/list/?page_size=1000')
        .then((countryRes) => {
          if (countryRes?.data?.results?.length) {
            const modContryOptions = countryRes.data.results.map(item => ({
              value: item.objid,
              label: item.country_name,
            }));
            setContriesOptions(modContryOptions || []);
          }
        })
        .catch(err => console.log(err));
    }
  };

  const getOtherStateList = (countryIdRes) => {
    if (countryIdRes || selectedCountryId) {
      api.get(`api/cfgstateprov/list/?cfg_country_objid=${countryIdRes || selectedCountryId}&page_size=1000`)
        .then((stateResp) => {
          if (stateResp?.data?.results?.length) {
            const modStateOptions = stateResp.data.results.map(item => ({
              value: item.objid,
              label: item.state_prov,
            }));
            setStateOtherOptions(modStateOptions || []);
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err));
    }
  };

  // Get all states from API FOR US only
  const getStatesList = (usStates) => {
    if (usStates?.length) {
      const modStateOptions = usStates.map(item => ({
        value: item.objid,
        label: item.state_prov,
      }));
      setStateOptions(modStateOptions || []);
    } else {
      api.get(`api/cfgstateprov/list/?cfg_country_objid=${240}&page_size=1000`)
        .then((stateResp) => {
          if (stateResp?.data?.results?.length) {
            const modStateOptions = stateResp.data.results.map(item => ({
              value: item.objid,
              label: item.state_prov,
            }));
            setStateOptions(modStateOptions || []);
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err));
    }
  };

  useEffect(() => {
    if (payoutDetails?.results?.length) {
      const { results } = payoutDetails;
      const {
        iban: IBAN, institution_number: INSTITUTION, routing_number: ROUTING,
        // eslint-disable-next-line max-len
        swift_code: SWIFT, perf_payment_method: selectedPaymentMethod, cosmo_payment_san_number: cosmoSAN,
      } = results[0];
      const localPaymentMethod = selectedPaymentMethod === 'Cosmo' ? 'Cosmo' : selectedPaymentMethod && selectedPaymentMethod !== 'Cosmo' && 'Others';
      setPaymentOption(localPaymentMethod);
      setUserSetSelectedPaymentOption(selectedPaymentMethod);
      setSAN(cosmoSAN);

      if (IBAN || INSTITUTION || ROUTING || SWIFT) {
        const {
          bank_location: {
            country: bankLocationId, countries: otherCountries = [],
            states: usStates = [],
          },
          perf_payment_method: prefMethod,
        } = results[0];

        const { bank_address: bankAddressObj = null } = results[0];

        let cntryObjID;

        if (bankAddressObj !== null) {
          cntryObjID = bankAddressObj.country.objid;
          // const { bankAddress: { country: { objid: cntryObjID = {} } = null } = {} }
        }

        if (bankLocationId === 'United States') {
          getStatesList(usStates);
        }

        if (bankLocationId === 'Other(International)' && cntryObjID && otherCountries.length) {
          getCountriesList(otherCountries);
          getOtherStateList(cntryObjID);
        }

        const location = LOCATIONS.filter(loc => loc.value === bankLocationId)[0] || null;

        const preference = PREFERENCES[bankLocationId].filter(
          pref => pref.value === prefMethod,
        )[0] || null;
        // const preference = prefMethod;

        setSelectedLocation(location);
        setSelectedPreference(preference);
        setPreferenceOptions(PREFERENCES[bankLocationId] || []);

        let initialFormValues = { };
        if (bankLocationId === 'United States') {
          // initialisng us's form
          const {
            account_number: accountNo,
            routing_number: routingNo,
            branch_number: branchNo,
            account_type: accountTypeVal,
            bank_address: bankAddObj,
          } = results[0];

          const {
            address_1: bankAddress, city: bankCity, zipcode,
            address2cfg_state_prov_objid: stateId,
            // country: { country_name: countryName },
          } = bankAddObj;

          const accountType = US_ACCOUNT_TYPES.filter(
            type => type.value === accountTypeVal,
          )[0] || null;
          setSelectedAccountType(accountType);

          const state = stateOptions?.filter(
            type => type.value === stateId,
          )[0] || null;

          setSelectedState(state);

          initialFormValues = {
            ...initialFormValues,
            accountNo,
            branchNo,
            routingNo,
            bankAddress,
            bankCity,
            zipcode,
            accountType: accountTypeVal,
          };
        } else if (bankLocationId === 'Canada') {
          // initialisng canada's form
          const {
            account_number: accountNo,
            institution_number: institutionNo,
            branch_number: branchNo,
          } = results[0];
          initialFormValues = {
            ...initialFormValues, accountNo, branchNo, institutionNo,
          };
        } else if (bankLocationId === 'UK & Euro Zone (IBAN)') {
          // initialisng other's form
          const { iban } = results[0];
          initialFormValues = { ...initialFormValues, ibanNo: iban };
        } else {
          // initialisng other's form
          const {
            account_number: accountNo,
            branch_number: branchNo,
            account_type: accountTypeVal,
            bank_name: bankName,
            bank_address: bankAddObj,
            swift_code: swiftCode,
          } = results[0];

          const {
            address_1: bankAddress, city: bankCity, zipcode,
            address2cfg_state_prov_objid: stateId,
          } = bankAddObj;

          const accountType = US_ACCOUNT_TYPES.filter(
            type => type.value === accountTypeVal,
          )[0] || null;
          setSelectedAccountType(accountType);

          const state = stateOptions?.filter(
            type => type.value === stateId,
          )[0] || null;

          setSelectedOthersStates(state);
          // setSelectedState(state);

          initialFormValues = {
            ...initialFormValues,
            accountNo,
            branchNo,
            bankName,
            bankAddress,
            bankCity,
            zipcode,
            swiftCode,
            accountType: accountTypeVal,
          };
        }
        initialize(initialFormValues);
      }
    }
  }, [payoutDetails]);


  useEffect(() => {
    if (countriesOptions?.length && payoutDetails?.results?.length) {
      const { results } = payoutDetails;
      const { bank_address: bankAddObj } = results[0];

      if (bankAddObj) {
        const { country: { objid: countryObjid } } = bankAddObj;
        const country = countriesOptions?.filter(
          type => type.value === countryObjid,
        )[0] || null;
        // intitalising country select with the state value
        change('country', (country?.value || null));
        if (country.label === 'UNITED STATES') {
          setSelectedCountry(null);
        } else setSelectedCountry(country);
      }
    }
  }, [countriesOptions]);

  useEffect(() => {
    if (stateOtherOptions?.length && payoutDetails?.results?.length) {
      const { results } = payoutDetails;
      const { bank_address: bankAddObj } = results[0];

      if (bankAddObj) {
        const { address2cfg_state_prov_objid: stateId } = bankAddObj;
        const state = stateOtherOptions?.filter(
          type => type.value === stateId,
        )[0] || null;
        // intitalising state select with the state value
        change('stateOther', (state?.value || null));
        setSelectedOthersStates(state);
      }
    }
  }, [stateOtherOptions]);

  useEffect(() => {
    if (stateOptions?.length && payoutDetails?.results?.length) {
      const { results } = payoutDetails;
      const { bank_address: bankAddObj } = results[0];

      if (bankAddObj) {
        const { address2cfg_state_prov_objid: stateId } = bankAddObj;
        const state = stateOptions?.filter(
          type => type.value === stateId,
        )[0] || null;
        // intitalising state select with the state value
        change('state', (state?.value || null));
        setSelectedState(state);
      }
    }
  }, [stateOptions]);

  useEffect(() => {
    if (selectedLocation && selectedLocation.value === 'Other(International)') {
      getCountriesList();
      getOtherStateList();
    }
  }, [selectedLocation]);

  useEffect(() => {
    // getStatesList();
    if (selectedLocation && selectedLocation.value === 'United States') {
      getStatesList();
    }
  }, [selectedLocation]);

  useEffect(() => {
    getOtherStateList();
  }, [selectedCountryId]);

  const locationHandler = (option) => {
    setSelectedLocation(option);
    if (option.value === 'Other(International)' && payoutDetails.results.length === 0) {
      setPreferenceOptions([]);
      setSelectedPreference(null);
    }
    if (option.value !== 'Other(International)') {
      setPreferenceOptions(PREFERENCES[option.value] || []);
      setSelectedPreference(null);
    } else if (option.value === 'Other(International)' && payoutDetails.results.length) {
      setPreferenceOptions(PREFERENCES[option.value] || []);
      setSelectedPreference(null);
    }


    // setSelectedState(null);
  };

  const preferenceHandler = (option) => {
    setSelectedPreference(option);
  };

  const accTypeHandler = (option) => {
    setSelectedAccountType(option);
  };

  const CountryHandler = (option) => {
    setselectedCountryId(option.value);
    setSelectedCountry(option);
  };

  const stateHandler = (option) => {
    setSelectedState(option);
  };
  const stateOtherHandler = (option) => {
    setSelectedOthersStates(option);
  };

  // ----- form submit handler -----

  const handleFormSubmit = (paymentMethod = 'others', CSAN = null) => {
    const { values } = payoutSettingsForm;

    const formData = new FormData();
    formData.append('payment_config2cst_contact', perf2cstContactId);
    if (paymentMethod === 'cosmo' && CSAN !== null) {
      formData.append('perf_payment_method', 'Cosmo');
      formData.append('cosmo_payment_san_number ', CSAN);
      postPayoutDetails(formData).then((res) => {
        if (ToastID.current) {
          toast.update(ToastID.current, { type: toast.TYPE.SUCCESS });
        } else {
          ToastID.current = toast.success('Bank details updated successfully');
        }
      }).catch((error) => {
        const errorMsg = error?.response?.data?.message || 'Bank details updation failed';
        if (ToastID.current) {
          toast.update(ToastID.current, { type: toast.TYPE.ERROR });
        } else {
          ToastID.current = toast.error(errorMsg);
        }
      });
      return;
    }
    formData.append('perf_payment_method', selectedPreference.value);
    if (selectedLocation.value === 'United States') {
      const {
        routingNo, accountNo, bankAddress, bankCity, state,
        zipcode,
      } = values;
      formData.append('account_number', accountNo);
      formData.append('account_type', selectedAccountType.value);
      formData.append('address_1', bankAddress);
      formData.append('address2cfg_state_prov', state);
      formData.append('city', bankCity);
      formData.append('zipcode', zipcode);
      formData.append('routing_number', routingNo);
      // if (selectedPreference.value === 'Direct') {
      //   formData.append('routing_number', routingNo);
      // } else {
      //   formData.append('swift_code', swiftCode);
      //   formData.append('bank_name', bankName);
      // }
    } else if (selectedLocation.value === 'Canada') {
      const { institutionNo, branchNo, accountNo } = values;
      formData.append('institution_number', institutionNo);
      formData.append('branch_number', branchNo);
      formData.append('account_number', accountNo);
    } else if (selectedLocation.value === 'UK & Euro Zone (IBAN)') {
      const { ibanNo } = values;
      formData.append('iban', ibanNo);
    } else {
      // other ka form
      const {
        accountNo, bankAddress, bankCity, stateOther,
        zipcode, swiftCode, bankName,
      } = values;
      formData.append('account_number', accountNo);
      formData.append('account_type', selectedAccountType.value);
      formData.append('address_1', bankAddress);
      formData.append('address2cfg_state_prov', stateOther);
      formData.append('city', bankCity);
      formData.append('zipcode', zipcode);
      formData.append('swift_code', swiftCode);
      formData.append('bank_name', bankName);
    }
    postPayoutDetails(formData).then((res) => {
      if (ToastID.current) {
        toast.update(ToastID.current, { type: toast.TYPE.SUCCESS });
      } else {
        ToastID.current = toast.success('Bank details updated successfully');
      }
    }).catch((error) => {
      const errorMsg = error?.response?.data?.message || 'Bank details updation failed';
      if (ToastID.current) {
        toast.update(ToastID.current, { type: toast.TYPE.ERROR });
      } else {
        ToastID.current = toast.error(errorMsg);
      }
    });
  };

  // ----- US option Sub-Payout Starts -----
  const UsSubPayout = () => (
    <Row className="row_detail mb-3">
      <Col xs={4}>
        <Label>
          Routing Number
          *
        </Label>
        {selectedPreference.value === 'Direct' && (
        <Field
          className="perf-login-username"
          type="number"
          name="routingNo"
          component={renderField}
          validate={[required, exactLen9]}
          placeholder="123456789"
          // validationError="Please provide Routing Number"
        />
        )}
      </Col>
      <Col xs={4}>
        <Label>
            Account Number*
        </Label>
        <Field
          className="perf-login-username"
          type="text"
          name="accountNo"
          component={renderField}
          validate={[required, minLen6, maxLen18]}
          placeholder="45444544532155"
          maxLength={19}
          // validationError="Please provide Account Number"
        />
      </Col>
      <Col xs={4}>
        <Label htmlFor="Country">
            Account Type
        </Label>
        <Field
          name="accountType"
          selectedOption={selectedAccountType || null}
          options={US_ACCOUNT_TYPES || []}
          handleChange={accTypeHandler}
          component={renderReactSelectField}
          placeholder="Select"
          isSearchable={false}
          noOptionsMessage="Please select an option"
          className="custom-dropdown"
        />
      </Col>
    </Row>
  );
  // ----- US options Sub-Payout Ends -----

  // ----- Canada option Sub-Payout Starts -----
  const CanadaSubPayout = () => (
    <Row className="row_detail mb-3">
      <Col xs={4}>
        <Label>
            Institution Number*
        </Label>
        <Field
          className="perf-login-username"
          type="number"
          name="institutionNo"
          component={renderField}
          validate={[required, exactLen3]}
          placeholder="789"
          // validationError="Please provide Institution Number"
        />
      </Col>
      <Col xs={4}>
        <Label>
            Branch Number*
        </Label>
        <Field
          className="perf-login-username"
          type="number"
          name="branchNo"
          component={renderField}
          validate={[required, exactLen5]}
          placeholder="12345"
          // validationError="Please provide Branch Number"
        />
      </Col>
      <Col xs={4}>
        <Label>
            Account Number*
        </Label>
        <Field
          className="perf-login-username"
          type="text"
          name="accountNo"
          component={renderField}
          validate={[required, minLen7, maxLen12]}
          placeholder="45444544532155"
          maxLength={13}
          // validationError="Please provide Account Number"
        />
      </Col>
    </Row>
  );
  // ----- Canada options Sub-Payout Ends -----

  // ----- Others option Sub-Payout Starts -----
  const EuroZoneSubPayout = () => (
    <Row className="row_detail mb-3">
      <Col xs={4}>
        <Label>
            IBAN Number*
        </Label>
        <Field
          className="perf-login-username"
          type="text"
          name="ibanNo"
          component={renderField}
          validate={[required, minLen9, maxLen34]}
          placeholder="GB 12 ABCD 102030 123456789"
          // validationError="Please provide IBAN code"
        />
      </Col>
    </Row>
  );
  // ----- Others options Sub-Payout Ends -----

  // ----- Others wire option Sub-Payout Starts -----
  const OthersSubPayout = () => (
    <Row className="row_detail mb-3">
      <Col xs={4}>
        <Label>
          Swift Code
          *
        </Label>
        {selectedPreference.value === 'Wire' && (
        <Field
          className="perf-login-username"
          type="text"
          name="swiftCode"
          component={renderField}
          validate={[required, minLen7, maxLen11]}
          placeholder="ABCDDGHDH"
          // validationError="Please provide Swift Code"
        />
        )}
      </Col>
      <Col xs={4}>
        <Label>
            Account Number*
        </Label>
        <Field
          className="perf-login-username"
          type="text"
          name="accountNo"
          component={renderField}
          validate={[required, minLen6, maxLen18]}
          placeholder="45444544532155"
          maxLength={19}
          // validationError="Please provide Account Number"
        />
      </Col>
      <Col xs={4}>
        <Label htmlFor="Country">
            Account Type
        </Label>
        <Field
          name="accountType"
          selectedOption={selectedAccountType || null}
          options={US_ACCOUNT_TYPES || []}
          handleChange={accTypeHandler}
          component={renderReactSelectField}
          placeholder="Select"
          isSearchable={false}
          noOptionsMessage="Please select an option"
          className="custom-dropdown"
        />
      </Col>
    </Row>
  );
  // ----- Others options Sub-Payout Ends -----


  // ----- Bank Detail Fields - Wire transfers starts -----
  const BankDetailFieldsUSWire = () => (
    <>
      <Card className="payout-card">
        <CardBody className="p-0">
          <CardHeader className="customCardHeader pl-0">
            <h2>Bank Details</h2>
          </CardHeader>
          <Row className="pt-2">
            <Col xs={12}>
              <Row className="row_detail mb-3">
                <Col xs={4}>
                  <Label htmlFor="Country">
                    Bank Name*
                  </Label>
                  <Field
                    className="perf-login-username"
                    type="text"
                    name="bankName"
                    component={renderField}
                    validate={[required]}
                    placeholder="Bank of America"
                    validationError="Please provide bank name"
                  />
                </Col>
                <Col xs={8}>
                  <Label htmlFor="Country">
                    Address*
                  </Label>
                  <Field
                    className="perf-login-username"
                    type="text"
                    name="bankAddress"
                    component={renderField}
                    validate={[required]}
                    placeholder="71 Cherry Court SO53 SPD US"
                    validationError="Please provide bank address"
                  />
                </Col>
              </Row>
              <Row className="row_detail mb-3">
                <Col xs={4}>
                  <Label htmlFor="Country">
                    Bank Location
                  </Label>
                  {/* <Field
                    className="perf-login-username"
                    type="text"
                    name="bankLocation"
                    component={renderField}
                    validate={[required]}
                    placeholder="United States"
                    validationError="Please provide location"
                  /> */}
                  <Field
                    name="country"
                    selectedOption={selectedCountry || null}
                    options={countriesOptions || []}
                    handleChange={CountryHandler}
                    component={renderReactSelectField}
                    validate={[required]}
                    placeholder="Select"
                    isSearchable={false}
                    validationError="Please select a country"
                    className="custom-dropdown"
                  />

                  {/* <Input id="displayName" value={selectedLocation.label}
                  readOnly style={{ opacity: '0.6' }} /> */}
                </Col>
                <Col xs={4}>
                  <Label htmlFor="Country">
                    State/Province*
                  </Label>
                  <Field
                    name="stateOther"
                    selectedOption={selectedOthersStates || null}
                    options={stateOtherOptions || []}
                    handleChange={stateOtherHandler}
                    component={renderReactSelectField}
                    validate={[required]}
                    placeholder="Select"
                    isSearchable={false}
                    validationError="Please select a state"
                    className="custom-dropdown"
                  />
                </Col>
                <Col xs={4}>
                  <Label htmlFor="Country">
                    City*
                  </Label>
                  <Field
                    className="perf-login-username"
                    type="text"
                    name="bankCity"
                    component={renderField}
                    validate={[required]}
                    placeholder="San Francisco"
                    validationError="Please provide city"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row_detail mb-3">
            <Col xs={4}>
              <Label htmlFor="Country">
                    Zipcode*
              </Label>
              <Field
                className="perf-login-username"
                type="text"
                name="zipcode"
                component={renderField}
                validate={[required]}
                placeholder="90001"
                validationError="Please provide zipcode"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
  // ----- Bank Detail Fields - US Wire transfers ends -----

  // ----- Bank Detail Fields - US Direct transfers starts -----
  const BankDetailFieldsUSDirect = () => (
    <>
      <Card className="payout-card">
        <CardBody className=" p-0">
          <CardHeader className="customCardHeader pl-0">
            <h2>Bank Details</h2>
          </CardHeader>
          <Row className="pt-2">
            <Col xs={12}>
              <Row className="row_detail mb-3">
                <Col xs={8}>
                  <Label htmlFor="Country">
                      Address*
                  </Label>
                  <Field
                    className="perf-login-username"
                    type="text"
                    name="bankAddress"
                    component={renderField}
                    validate={[required]}
                    placeholder="71 Cherry Court SO53 SPD US"
                    validationError="Please provide address"
                  />
                </Col>
                <Col xs={4}>
                  <Label htmlFor="Country">
                    Bank Location
                  </Label>
                  {/* <Field
                    className="perf-login-username"
                    type="text"
                    name="bankLocation"
                    component={renderField}
                    validate={[required]}
                    placeholder="United States"
                    validationError="Please provide bank location"
                  /> */}
                  <Input id="displayName" value={selectedLocation.label} readOnly style={{ opacity: '0.6' }} />
                </Col>
              </Row>
              <Row className="row_detail mb-3">
                <Col xs={4}>
                  <Label htmlFor="Country">
                      State/Province*
                  </Label>
                  <Field
                    name="state"
                    selectedOption={selectedState || null}
                    options={stateOptions || []}
                    handleChange={stateHandler}
                    component={renderReactSelectField}
                    validate={[required]}
                    placeholder="Select"
                    isSearchable={false}
                    validationError="Please select a state"
                    className="custom-dropdown"
                  />
                </Col>
                <Col xs={4}>
                  <Label htmlFor="Country">
                      City*
                  </Label>
                  <Field
                    className="perf-login-username"
                    type="text"
                    name="bankCity"
                    component={renderField}
                    validate={[required]}
                    placeholder="San Francisco"
                    validationError="Please provide city"
                  />
                </Col>
                <Col xs={4}>
                  <Label htmlFor="Country">
                    Zipcode*
                  </Label>
                  <Field
                    className="perf-login-username"
                    type="text"
                    name="zipcode"
                    component={renderField}
                    validate={[required]}
                    placeholder="90001"
                    validationError="Please provide zip code"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
  // ----- Bank Detail Fields - Wire transfers ends -----

  const BankDetailsSection = () => (
    <>
      {selectedLocation.value === 'United States' && selectedPreference.value === 'Direct' && BankDetailFieldsUSDirect()}
      {selectedLocation.value === 'Other(International)' && selectedPreference.value === 'Wire' && BankDetailFieldsUSWire()}
      <Row className="mt-4">
        <Col xs={12}>
          {isLoading ? (
            <Button color="primary" className="updateBtn px-5" disabled>
                Saving...
              <i className="fa fa-spinner" />
            </Button>
          ) : (
            <>
              <Button color="primary" className="updateBtn px-5">Save</Button>
              <Button
                onClick={() => setPaymentOption('')}
                color="primary"
                outline
                className="btn btn-outline-primary ml-4"
              >
    Change Bank Details
              </Button>
            </>

          )}

        </Col>
      </Row>
    </>
  );

  const SUB_PAYOUT_FIELDS = {
    'United States_Direct': UsSubPayout,
    Canada_Direct: CanadaSubPayout,
    'UK & Euro Zone (IBAN)_Wise': EuroZoneSubPayout,
    'Other(International)_Wire': OthersSubPayout,
  };

  const RenderPaymentMethod = () => (
    selectedLocation?.value === 'Other(International)' && payoutDetails?.results[0]?.perf_payment_method !== 'Wire' ? (
      <Col xs={12} className="mt-4">
        <p>
You need to open a personal account with
          {' '}
          {' '}
          <a href="https://www.cosmopayment.com/">https://www.cosmopayment.com/</a>
          {' '}
in order to receive funds from us.
        </p>
      </Col>
    ) : (
      selectedLocation?.value === 'Other(International)' && payoutDetails?.results?.length && payoutDetails?.results[0]?.perf_payment_method === 'Wire' ? (
        <Col xs={4}>
          <Label htmlFor="Country">
     Preferred Option
          </Label>
          <Field
            name="preference"
            selectedOption={selectedPreference || null}
            options={preferenceOptions || []}
            handleChange={preferenceHandler}
            component={renderReactSelectField}
            placeholder="Select"
            isSearchable={false}
            noOptionsMessage="Please select an option"
            className="custom-dropdown"
          />
        </Col>
      ) : (
        <Col xs={4}>
          <Label htmlFor="Country">
     Preferred Option
          </Label>
          <Field
            name="preference"
            selectedOption={selectedPreference || null}
            options={preferenceOptions || []}
            handleChange={preferenceHandler}
            component={renderReactSelectField}
            placeholder="Select"
            isSearchable={false}
            noOptionsMessage="Please select an option"
            className="custom-dropdown"
          />
        </Col>
      )
    )
  );

  return (
    <form className="mt-3 edit-info" onSubmit={handleSubmit(handleFormSubmit)}>
      <Card className="payout-card">
        <CardBody>
          {/* <CardHeader className="customCardHeader pl-0">
            <h2>Payout</h2>
          </CardHeader> */}
          {paymentOption === '' && (
          <PaymentMethodsSelection
            paymentOption={paymentOption}
            setPaymentOption={setPaymentOption}
            selectedPaymentMethod={userSetSelectedPaymentOption}
          />
          )
        }

          {paymentOption === 'Cosmo' ? (
            <CosmoPayment
              handleFormSubmit={handleFormSubmit}
              paymentOption={paymentOption}
              setPaymentOption={setPaymentOption}
              SAN={SAN}
            />
          ) : paymentOption === 'Others' && (
            <Row className="pt-2">
              <Col xs={12}>
                <Row className="row_detail mb-3">
                  <Col xs={4}>
                    <Label htmlFor="Country">
           Where is your Bank located?
                    </Label>
                    <Field
                      name="location"
                      selectedOption={selectedLocation || null}
                      options={LOCATIONS || []}
                      handleChange={locationHandler}
                      component={renderReactSelectField}
                      placeholder="Select"
                      isSearchable={false}
                      noOptionsMessage="Please select an option"
                      className="custom-dropdown"
                    />
                  </Col>

                  {RenderPaymentMethod()}
                </Row>
                {
       (selectedLocation && selectedPreference && (
         <>
           {
             SUB_PAYOUT_FIELDS[`${selectedLocation.value}_${selectedPreference.value}`]()
           }
           {BankDetailsSection()}
         </>
       ))
       || (
         null
       )
     }
              </Col>
            </Row>

          )}


        </CardBody>
      </Card>
    </form>
  );
};

PayoutSettings.defaultProps = {
  // perfObjId: 0,
  payoutSettingsForm: {},
};

PayoutSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  postPayoutDetails: PropTypes.func.isRequired,
  perf2cstContactId: PropTypes.number.isRequired,
  payoutSettings: PropTypes.instanceOf(Object).isRequired,
  // payoutDetails: PropTypes.instanceOf(Object),
  // isLoading: PropTypes.bool.isRequired,
  initialize: PropTypes.func.isRequired,
  payoutSettingsForm: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
};

export default PayoutSettings;
