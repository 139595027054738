import React from 'react';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import { AiOutlineWarning } from 'react-icons/ai';


const RefundConfirmationModal = (props) => {
  const {
    className,
    header,
    // eslint-disable-next-line react/prop-types
    objid,
    open,
    toggle,
    onClick,
    deleteId,
  } = props;

  const confirmDelete = (id) => {
    onClick(id);
    toggle();
  };

  const closeModal = () => {
    toggle();
  };

  return (
    <Modal
      className={`EditInfo-Modal delete-number-modal RefundConfirmationModal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} />
      <ModalBody className="chat-modal-body">
        <div>
          <AiOutlineWarning style={{
            width: '50px',
            height: '50px',
            color: 'var(--primary)',
          }}
          />

        </div>
        <h4>
          {' '}
          {header}
        </h4>
        <p>{`${objid.credit_amount} credits will be refunded to ${objid.customer_screen_name}`}</p>
        <div className="d-flex">
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary mr-2"
            onClick={() => confirmDelete(deleteId)}
          >
            Ok
          </Button>
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

RefundConfirmationModal.defaultProps = {
  className: '',
  header: '',
  open: false,
  deleteId: 0,
};

RefundConfirmationModal.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  deleteId: PropTypes.number,
};

export default RefundConfirmationModal;
