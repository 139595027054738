/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import { PropTypes } from 'prop-types';
import {
  Card, CardHeader, CardBody,
  Label, Dropdown, Button,
  DropdownToggle, DropdownMenu, DropdownItem,
  Tooltip,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { getHistory } from '../../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import Conversation from './conversation';
import SwitchButton from '../../../../utils/SwitchButton';
import IntialScreen from './InitialScreen';
import CameraModal from '../CameraModal';
import MediaOptionModal from '../MediaOptionModal';
import PrivateMediaOptionModal from '../PrivateMediaOptionModal';
import ShareMediaOptionModal from '../ShareMediaOptionModal';
import ChatMediaOptionModal from '../ChatMediaOptionModal';
import ChatMediaShareOptionModal from '../ChatMediaShareOptionModal';
// import cameraIcon from '../../../../assets/svgs/camera_icon';
import './style.scss';
import cameraChat from '../../../../assets/svgs/camera_chat';
import galleryChat from '../../../../assets/svgs/gallery_chat';
import sendChat from '../../../../assets/svgs/send_chat';
// import BlockMemberCard from './BlockMember/BlockMember';
import CustomModal from '../../../../components/CustomModal';
import CustomerReviewModal from '../../../CustomerReviews/components/CustomerReviewModal';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import backButtonIcon from '../../../../assets/svgs/backButtonIcon';
import crownIcon from '../../../../assets/svgs/icon-crown.svg';
import microphone from '../../../../assets/svgs/microphone.svg';
import Media from '../../../../assets/svgs/Media.svg';
import NotesIcon from '../../../../assets/svgs/notesicon.svg';
import DollarActiveIcon from '../../../../assets/svgs/Dollar-active.svg';
import DollarInactiveIcon from '../../../../assets/svgs/Dollar-inactive.svg';
import RecordingMic from '../../../../assets/svgs/Recording.svg';
import api from '../../../../actions/api';
import MediaModal from '../MediaModal';
import { setScrollAtBottom } from '../../../../utils/helpers';
import AlreadySharedMediaModal from '../AlreadySharedMediaModal';
import WhispererModal from '../WhispererModal';
import NotesModal from '../NotesModal';
import { editCustomerNote } from '../../../../actions/actionFavouriteCustomer';
import whispererBotNew from '../../../../assets/gif/chat_bot_new.gif';


const userMessage = 'Media sharing is only allowed after you have sent at least 3 messages.';
const LastMessageNotByCustomer = 'Whisperer can only suggest when the customer sends a new message.';
let toastID;

let playerInterval;
const AllConversation = (props) => {
  const {
    activeUser = {}, sendMessage,
    sendMedia,
    chatValidationData,
    getPrivateMedia,
    privateProductList,
    next,
    privateMediaCount,
    chatMsgLoading,
    onChatBackHandler,
    minImagePurchaseCredits,
    minVideoPurchaseCredits,
    minAudioPurchaseCredits,
    performerMediaPrices,
    checkActiveSessionFun,
    resumeBtndisabled,
    getChatMediaPhotosState,
    getChatMediaVideosState,
    getChatMediaPhotosFun,
    getChatMediaVideosFun,
    createCustomerReviewFun,
    isRoomToggled,
    activeUserOnlineStatus,
    blockedInactiveSessionCustomer,
    charObjid,
    newActiveUserId,
    SubmitForwardRequest,
    fetchMoreData,
    hasMore,
    infiniteloading,
    isSessionExpired,
    activeSessionID,
    setIsSessionExpired,
    onlineStatusHandler,
  } = props;

  const { isActiveForChat = false, minCharLength = 15, floodedMsg = false } = chatValidationData;
  const [message, setMessage] = useState('');
  const [isMinMsgsSent, setIsMinMsgsSent] = useState(false);
  const [isSnapPhotomodalOpen, setSnapPhotomodalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isShowMessageLengthError, setShowMessageLengthError] = useState(false);
  const [isShowMediaOptionModal, setisShowMediaOptionModal] = useState(false);
  const [isOpenPrivateMediaModal, setIsOpenPrivateMediaModal] = useState(false);
  const [BlockMemberModalOpen, setBlockMemberModalOpen] = useState(false);
  const [isOpenShareMediaModal, setIsOpenShareMediaModal] = useState(false);
  const [ReportMemberModalOpen, setReportMemberModalOpen] = useState(false);
  const [reportMemberRadioButtons, setReportMemberRadioButtons] = useState([]);
  const [reportDataValue, setReportDataValue] = useState('#REPORT');
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedChatMedia, setSelectedChatMedia] = useState(null);
  const [isOpenChatMediaModal, setIsOpenChatMediaModal] = useState(false);
  const [isMediaModal, setIsMediaModal] = useState(false);
  const [isOpenChatMediaShareModal, setIsOpenChatMediaShareModal] = useState(false);
  const [reportMemberEndSessionModalOpen, setReportMemberEndSessionModalOpen] = useState(false);
  const [premiumChatStatus, setPremiumChatStatus] = useState(false);
  const [isOpenAlreadyShareMediaModal, setIsOpenAlreadyShareMediaModal] = useState(false);
  const [isSelectAlreadySharedMedia, setIsSelectAlreadySharedMedia] = useState(null);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [audioTooltip, setAudioTooltip] = useState(false);
  const [lastMsgTooltip, setLastMsgTooltip] = useState(false);
  const [onKeyPressInput, setOnKeyPressInput] = useState(false);
  // const [privateMediaFile, setPrivateMediaFile] = useState();
  // const [isRedirectToSupport, setRedirectToSupport] = useState(false);
  const [isCustomerReviewModalOpen, setIsCustomerReviewModalOpen] = useState(false);
  const [whispererModal, setWhispererModal] = useState(false);
  const [newReviewObj, setNewReviewObj] = useState(null);
  // eslint-disable-next-line max-len
  // const { customerReviewForm, interruptcmd, customerHostReviewObjChat = null } = JSON.parse(localStorage.getItem('messageChat')) || {};
  const customerData = JSON.parse(localStorage.getItem('messageChat')) || {};
  const customerHostReviewObj = localStorage.getItem('customerHostReviewLocal') || null;
  const [isFav, setIsFav] = useState(false);
  const [isChatEnabled, setIsChatEnabled] = useState(false);
  const SystemConfig = useSelector(state => state.sysConfig?.data?.results);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  // console.log('customerHostReviewObj', customerHostReviewObj && customerHostReviewObj);
  // console.log('newReviewObj.creative', newReviewObj && newReviewObj.creative);

  // RECORDING CODE STARTS HERE
  const StartButton = useRef();
  const StopButton = useRef();
  const [audioAvailable, setAudioAvailable] = useState(false);
  // const [audioStatus, setAudioStatus] = useState('');
  const [recordedSeconds, setRecordedSeconds] = useState(0);
  // const [recordedDisplaySeconds, setRecordedDisplaySeconds] = useState(0);
  // const [mediaRecorderEnabled, setMediaRecorderEnabled] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  // const [recorder, setRecorder] = useState(null);
  // const [audioURL, setAudioURL] = useState('');
  const [isBlocked, setIsBlocked] = useState('');
  const endTime = 31; // in Seconds;
  const visualizer = useRef();
  const requestIdRef = useRef(null);
  const Mp3Recorder = useRef(null);
  const [emojis, setEmojis] = useState([]);
  const [videoDuration, setVideoDuration] = useState(null);
  const [sharedPhotos, setSharedPhotos] = useState([]);
  const [sharedVideos, setSharedVideos] = useState([]);

  useEffect(() => {
    if (activeUser) {
      setPremiumChatStatus(activeUser.premium);
    }
  }, [activeUser?.premium]);

  useEffect(() => {
    if (activeUser) {
      setIsFav(activeUser.isFavCustomer);
    }
  }, [activeUser]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('mobileChatData'))) {
      const isChatEnabledVal = JSON.parse(localStorage.getItem('mobileChatData'));
      console.log('isChatEnabledVal', isChatEnabledVal.is_chatbot_enabled);
      setIsChatEnabled(isChatEnabledVal.is_chatbot_enabled);
    }
  }, []);

  useEffect(() => {
    if (SystemConfig?.length) {
      const chatEnabledOrNot = SystemConfig.find(el => el.config_name === 'is_chatbot_enabled');
      if (chatEnabledOrNot !== -1) {
        setIsChatEnabled(chatEnabledOrNot.value === 'True');
      }
    }
  }, [SystemConfig]);

  // Fetch emojis from the API when the component mounts
  useEffect(() => {
    if (emojis && !emojis.length) {
      api.get('api/emoji/list/?related_to=character')
        .then(response => response.data)
        .then((data) => {
          setEmojis(data.results); // API returns an array of emoji objects
        })
        .catch((error) => {
          console.error('Error fetching emojis:', error);
        });
    }
  }, [emojis]);

  // RECORDING CODE ENDS HERE
  useEffect(() => {
    if (customerData) {
      const newCustomerReviewStates = {};
      Object.keys(customerData).forEach((customerId) => {
        const {
          customerReviewForm,
          interruptcmd,
          customerHostReviewObjChat = null,
        } = customerData[customerId];
        newCustomerReviewStates[customerId] = {
          showModal: interruptcmd === 'ENDSESSION' && customerReviewForm,
        };
      });

      setIsCustomerReviewModalOpen(newCustomerReviewStates);
    }
    // if (interruptcmd === 'ENDSESSION') {
    //   console.log('Interrupt', customerReviewForm);
    //   if (customerReviewForm) {
    //     console.log('InterruptInside', customerReviewForm);
    //     setIsCustomerReviewModalOpen(true);
    //   }
    // }
  }, [
    customerData[activeUser?.custId]?.customerReviewForm,
    customerData[activeUser?.custId]?.interruptcmd]);

  // RECORDING FUNCTIONALITY CODE STARTS HERE

  useEffect(() => {
    Mp3Recorder.current = new MicRecorder({ bitRate: 128 });
  }, []);

  useEffect(() => {
    if (isRecording) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        setAudioAvailable(stream);
        setIsBlocked(false);
      }).catch((error) => {
        console.log(error);
        setIsBlocked(true);
      });
    }
  }, [isRecording]);


  const handleFavUnFavIconClick = (val) => {
    setIsFav(val);
    console.log('fav', val);
    props.favUnFavHandlerFun(val);
  };

  // eslint-disable-next-line consistent-return
  const isMessageLimitReached = (messages) => {
    // const sessionObjID = messages[messages.length - 1]?.sessionObjId;
    const sessionStatus = localStorage.getItem('sessionStatus') ? JSON.parse(localStorage.getItem('sessionStatus')) : '';
    const sessionID = localStorage.getItem('sessionID') ? JSON.parse(localStorage.getItem('sessionID')) : 0;
    if (messages.length) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const hostMessages = messages.filter((msg) => {
        if (msg.type === 'Char2Cust' && msg.direction === 'out' && activeUser?.sessionStatus !== 'off' && Number(msg.sessionId) === Number(sessionID)) {
          return msg;
        }
      });
      if (hostMessages.length >= 3) {
        setIsSessionExpired(false);
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (activeUser && activeUser.messages) {
      setIsMinMsgsSent(isMessageLimitReached(activeUser.messages));
    }
  }, [activeUser?.messages]);

  useEffect(() => {
    if (!visualizer.current) return;
    const canvasContext = visualizer.current?.getContext('2d');
    if (isRecording && audioAvailable) {
      const Ctx = window.AudioContext || window.webkitAudioContext;
      const audioContext = new Ctx();
      const analyser = audioContext.createAnalyser();
      const mediaStreamSource = audioContext.createMediaStreamSource(audioAvailable);

      // Connecting the analyzer to the media source
      mediaStreamSource.connect(analyser);
      analyser.fftSize = 256;
      // eslint-disable-next-line no-use-before-define
      drawVisualizer();
      // eslint-disable-next-line no-inner-declarations
      function drawVisualizer() {
        requestIdRef.current = requestAnimationFrame(drawVisualizer);
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        analyser.getByteFrequencyData(dataArray);
        const width = visualizer?.current?.width;
        const height = visualizer?.current?.height;
        const barWidth = 10;
        // eslint-disable-next-line no-shadow
        const canvasContext = visualizer.current?.getContext('2d');
    // eslint-disable-next-line no-unused-expressions
    canvasContext?.clearRect(0, 0, width, height);
    let x = 0;
    if (canvasContext) {
      dataArray.forEach((item) => {
        const y = item / 255 * height * 1.1;
        canvasContext.strokeStyle = '#fd553d';

        x += barWidth;
        // eslint-disable-next-line no-unused-expressions
        canvasContext?.beginPath();
        canvasContext.lineCap = 'round';
        canvasContext.lineWidth = 2;
        canvasContext.moveTo(x, height);
        canvasContext.lineTo(x, height - y);
        canvasContext.stroke();
      });
    }
      }
    }
    // console.log('canvasContext?.canvas.width', canvasContext?.canvas.width);
    // eslint-disable-next-line no-unused-expressions
    visualizer.current && !isRecording
      && canvasContext?.clearRect(0, 0, canvasContext?.canvas.width, canvasContext?.canvas.height);
    // eslint-disable-next-line consistent-return
    return () => {
      cancelAnimationFrame(requestIdRef.current);
      //   !isRecording && audioAvailable?.getAudioTracks().forEach(track => {
      //     track.stop();
      // });
    };
  }, [audioAvailable, isRecording]);

  const startRecording = () => {
    if (isBlocked) {
      toast.error('Website is unable to access your microphone, please provide access to microphone in Settings.');
    } else {
      Mp3Recorder.current
        .start()
        .then(() => {
          setIsRecording(true);
          setIsOpenShareMediaModal(false);
          // eslint-disable-next-line no-use-before-define
          Counter(recordedSeconds, endTime);
          // eslint-disable-next-line no-unused-expressions
          message && setMessage('');
        }).catch(e => toast.error('Website is unable to access your microphone, please provide access to microphone in Settings.'));
    }
  };
  const stopRecording = (stopBtnClicked) => {
    // console.log({isRecording});
    // if (!isRecording) {
    Mp3Recorder.current
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setIsRecording(false);
        const audiofile = new File([blob], 'audiofile.mp3', {
          type: blob.type,
        });
        // console.log({blob});
        const selectedMediaObj = {
          text: '',
          name: 'VOICE',
          media: audiofile,
          mediaType: blob.type,
        };
        setSelectedMedia(selectedMediaObj);
        if (stopBtnClicked) {
          setIsOpenShareMediaModal(true);
        } else {
          setIsOpenShareMediaModal(false);
        }

        // console.log("requestIdRef.current", requestIdRef.current);
        cancelAnimationFrame(requestIdRef.current);
        // eslint-disable-next-line no-use-before-define
        ResetCounter();
        // eslint-disable-next-line no-unused-expressions
        audioAvailable?.getAudioTracks().forEach((track) => {
          track.stop();
        });
      }).catch(e => console.log(e));
  //  }
  };
  useEffect(() => {
    if (isRecording) {
      stopRecording(false);
    }
    if (message.length) setMessage('');
  }, [isRoomToggled?.objid]);

  const ResetCounter = () => {
    clearInterval(playerInterval);
    setRecordedSeconds(0);
  };

  const Counter = (StartTime, EndTime) => {
    let seconds = StartTime;
    playerInterval = setInterval(() => {
      seconds += 1;
      setRecordedSeconds(seconds);
      if (seconds >= EndTime) {
        stopRecording(true);
      }
    }, 1000);
  };

  // RECORDING FUNCTIONALITY CODE ENDS HERE


  useEffect(() => {
    if (customerHostReviewObj !== null) {
      let str = customerHostReviewObj;
      str = str.replace(/'/g, '"');
      const reviewObj = JSON.parse(str);
      const reviewObjVal = Object.values(reviewObj);
      const stateObj = {
        creative: reviewObjVal[0],
        generous: reviewObjVal[1],
        sexy: reviewObjVal[2],
      };
      setNewReviewObj(stateObj);
    }
  }, [customerHostReviewObj]);

  useEffect(() => {
    if (activeUser
      && customerData[activeUser.custId]
      && customerData[activeUser?.custId]?.customerHostReviewObjChat !== null) {
      let str = customerData[activeUser?.custId]?.customerHostReviewObjChat;
      str = str.replace(/'/g, '"');
      const reviewObjNoti = JSON.parse(str);
      const reviewObjValNoti = Object.values(reviewObjNoti);
      const stateObjNoti = {
        creative: reviewObjValNoti[0],
        generous: reviewObjValNoti[1],
        sexy: reviewObjValNoti[2],
      };
      setNewReviewObj(stateObjNoti);
    }
  }, [customerData[activeUser?.custId]?.customerHostReviewObjChat, activeUser]);

  const toggleDropDown = () => setDropdownOpen(prevState => !prevState);
  const chatCommand = [
    '#ban', '#Ban', '#BAN',
    '#report', '#REPORT',
    '#PIC', '#pic', '#Pic',
    '#underage', '#UNDERAGE', 'over', 'end', 'Over',
    'OVER', 'END', 'End',
    'Stop', 'STOP',
    'stop', '#Report',
  ];

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggleAudioTooltip = () => setAudioTooltip(!audioTooltip);
  const togglelastMsgTooltip = () => setLastMsgTooltip(!lastMsgTooltip);

  useEffect(() => {
    const radioButtonArray = [
      {
        title: 'Against terms',
        value: '#REPORT',
        subtitle: 'Block member from contacting you and Report to Admins',
      },
      {
        title: 'Illegal/Underage',
        value: '#UNDERAGE',
        subtitle: ' Block member access to platform and Report to Admins',
      },
    ];
    const radioButtonItems = radioButtonArray.map(rButton => (
      <div className="radioButton" key={rButton.title}>
        <label htmlFor={`radio${rButton.title}`}>
          <input
            type="radio"
            className="inputReportInput"
            name="reportButton"
            value={rButton.value}
            defaultChecked={reportDataValue === rButton.value}
            onChange={event => setReportDataValue(event.currentTarget.value)}
          />
          {rButton.title}
        </label>
        <p>{rButton.subtitle}</p>
      </div>
    ));
    setReportMemberRadioButtons(radioButtonItems);
  }, []);

  const sendMessageFunction = (isOver = '', reportAction = '') => {
    setOnKeyPressInput(false);
    if ((!isOver) && message && message.length < minCharLength
      && (!chatCommand.includes(message))) {
      setShowMessageLengthError(true);
    } else {
      let isCallAddMessage = true;
      let finalMessage = message;
      if (reportAction) {
        finalMessage = reportAction;
        isCallAddMessage = false;
      }
      // if (activeUser.performerContinueMsgCount >= 4) {
      //   isCallAddMessage = false;
      // }
      if (isOver && isOver === 'over') {
        finalMessage = 'over';
        isCallAddMessage = false;
      }
      if (finalMessage && finalMessage.trim() && errorMessage === '') {
        const msg = {
          text: finalMessage,
          media: '',
          isCallAddMessage,
        };
        sendMessage(msg);
        setTimeout(() => setScrollAtBottom({ elementId: 'chatWindow' }), 500);
        setMessage('');
        const textArea = document.querySelector('.write_msg');
        if (textArea) {
          textArea.style.height = '54px';
        }
      }
    }
  };

  function isValidMessage(msg) {
    if (chatCommand.includes(msg) === false && msg !== '') {
      const trimmedMessage = msg.replace(/\s+/g, ' ').trim();
      return trimmedMessage.length >= 20;
    }
    // eslint-disable-next-line no-unused-expressions
    chatCommand.includes(msg) && true;
    return true;
  }

  const checkCharactersLimits = (event) => {
    const maxCharacterLimit = 2000;
    const characterCount = event.target.value;
    if (characterCount.length <= maxCharacterLimit) {
      setMessage(characterCount);
      setErrorMessage('');
    } else {
      setErrorMessage(`Only ${maxCharacterLimit} characters are allowed.`);
    }
  };

  const onMessageChange = (event) => {
    const currentMessage = event.target.value;

    checkCharactersLimits(event);

    setMessage(currentMessage);
    if (!isValidMessage(currentMessage) || isRecording) {
      setShowMessageLengthError(true);
    } else {
      setShowMessageLengthError(false);
    }
    if (currentMessage.length > 0) {
      setOnKeyPressInput(true);
    } else {
      setOnKeyPressInput(false);
    }
    const textarea = document.querySelector('.write_msg');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight - 14}px`;

    // Check if content exceeds two lines
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10);
    const scrollHeight = textarea.scrollHeight - lineHeight;
    if (scrollHeight > 2 * lineHeight) {
      textarea.style.overflowY = 'scroll';
    } else {
      textarea.style.overflowY = 'hidden';
    }
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // eslint-disable-next-line no-unused-expressions
      !isShowMessageLengthError && (sendMessageFunction());
    }
  };

  const handlePremiumChat = (isChecked) => {
    let msg = {
      text: message,
      media: '',
    };
    if (isChecked) {
      msg = {
        ...msg,
        text: 'Billable',
      };
      sendMessage(msg, true);
    } else {
      msg = {
        ...msg,
        text: 'NonBillable',
      };
      sendMessage(msg, true);
    }
  };

  const closeMediaPopup = () => {
    setisShowMediaOptionModal(false);
  };

  const closeShareMediaPopup = () => {
    setIsOpenShareMediaModal(false);
  };

  const closeChatMediaShareModal = () => {
    setIsOpenChatMediaShareModal(false);
  };

  const onCustomerReviewSubmit = (data) => {
    const formData = new FormData();
    formData.append('cst_review2cst_contact', activeUser.custId);
    formData.append('creative', data.creative);
    formData.append('generous', data.generous);
    formData.append('sexy', data.sexy);
    formData.append('char_id', charObjid);
    createCustomerReviewFun(formData);
  };

  const onUploadPicture = (event = {}) => {
    const filesNew = event.target.files[0];
    // Check if the selected file is a video
    if (filesNew.type.startsWith('video/')) {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        // eslint-disable-next-line prefer-destructuring
        const duration = video.duration;
        console.log('video durations', duration);
        setVideoDuration(duration); // Set the duration in state
      };

      video.src = URL.createObjectURL(filesNew);
    } else {
      // Handle the case if the uploaded file is not a video
      console.error('Please upload a valid video file.');
    }

    const { files } = event.target;
    const fileSize = files[0].size;
    const fileInMb = (fileSize / 1024 / 1024).toFixed(2);

    if (fileInMb > 40) {
      toast.error('File size must be less or equal to 40MB.');
      setisShowMediaOptionModal(false);
      return false;
    }

    if (files.length > 0) {
      const allowedExtensions = /(\.tiff|\.jpg|\.jpeg|\.png|\.bmp|\.jfif|\.mp4|\.avi|\.mov|\.3gp|\.wmv|\.webm)$/i;
      if (!allowedExtensions.exec(files[0].name)) {
        toast.error('Uploaded file format is not supported.');
        const inputFiles = document.getElementById('picFile');
        inputFiles.value = '';
        setisShowMediaOptionModal(false);
        return false;
      }
      const selectedMediaObj = {
        text: '',
        media: files[0],
        mediaType: files[0].type,
      };
      setSelectedMedia(selectedMediaObj);
      closeMediaPopup();
      setIsOpenShareMediaModal(true);
    }
    return '';
  };

  const handleOnClickGallery = () => {
    setisShowMediaOptionModal(true);
  };

  const onUploadSnap = (imageFile = '') => {
    if (imageFile) {
      const msg = {
        text: '',
        media: imageFile,
        mediaType: imageFile.type,
      };
      setSelectedMedia(msg);
      setSnapPhotomodalOpen(false);
      setIsOpenShareMediaModal(true);
      // sendMessage(msg);
    }
  };

  const toggleCameraModal = () => {
    setSnapPhotomodalOpen(!isSnapPhotomodalOpen);
  };

  const redirectToSupport = () => {
    // if (isRedirectToSupport) {
    //   const getnewhistory = getHistory();
    //   getnewhistory.push('/#/support');
    //   setRedirectToSupport(true);
    // }
    window.location.hash = '#/support';
  };

  const endSession = () => {
    sendMessageFunction('over');
  };

  const blockEndedSessionCustomer = (value) => {
    console.log('value in conversation', value);
    blockedInactiveSessionCustomer(value);
  };

  const blockCustomer = (value = '') => {
    if (value) {
      sendMessageFunction('', value);
    }
  };

  // const renderHostxmppChatReview = () => {
  //   if (newReviewObj) {
  //     return (
  //       <div className="chat-customer-review">
  //         <div className="chat-reviews">
  //           <span>Creative : </span>
  //           <span>
  //             <i className="fa fa-star" />
  //             {newReviewObj.creative}
  //           </span>
  //         </div>
  //         <div className="chat-reviews">
  //           <span>Sexy : </span>
  //           <span>
  //             <i className="fa fa-star" />
  //             {newReviewObj.sexy}
  //           </span>
  //         </div>
  //         <div className="chat-reviews">
  //           <span>Generous : </span>
  //           <span>
  //             <i className="fa fa-star" />
  //             {newReviewObj.generous}
  //           </span>
  //         </div>
  //       </div>
  //     );
  //   }
  //   return '';
  // };

  const showOnlineStatus = (isCustomerOnline, sendEmailNotification) => {
    if (isCustomerOnline) {
      return <span className="online-small-icon" />;
      // eslint-disable-next-line no-else-return
    } else if (sendEmailNotification) {
      return <span className="online-away-icon" />;
    } else {
      return <span className="offline-small-icon" />;
    }
  };

  const WhispererModalHandler = () => {
    setWhispererModal(true);
  };

  function shouldShowButton(messages) {
    const sortedMsgs = messages
      .sort((a, b) => new Date(a.currentUTC) - new Date(b.currentUTC));
    let lastCust2HostIndex = -1;

    // eslint-disable-next-line no-plusplus
    for (let i = sortedMsgs.length - 1; i >= 0; i--) {
      const msg = sortedMsgs[i];

      if (msg.type === 'Cust2Char') {
        lastCust2HostIndex = i;
        break;
      }
    }

    if (lastCust2HostIndex === -1) {
      return false;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = lastCust2HostIndex + 1; i < sortedMsgs.length; i++) {
      const msg = sortedMsgs[i];

      if (msg.type === 'Char2Cust') {
        return false;
      } if (msg.type === 'Sys2Char') {
        // eslint-disable-next-line no-continue
        continue;
      } else if (msg.type === 'Cust2Char') {
        return true;
      }
    }

    // Check the last 'Cust2Char' message for media
    const lastCust2CharMsg = sortedMsgs[lastCust2HostIndex];
    if (lastCust2CharMsg?.media && lastCust2CharMsg.media !== '') {
      return false;
    }

    return true;
  }


  const msgFeildRender = () => {
    if (!isActiveForChat && activeUser?.sessionStatus === 'off') {
      return (
        <div className="chat-pref-msg">Please enable chat preference in Dashboard to continue using messaging.</div>
      );
    }

    if (activeUser?.sessionStatus === 'off' || floodedMsg) {
      return (
        <div className="resume-chat-btn">
          <Button
            color="primary"
            outline
            type="button"
            onClick={() => checkActiveSessionFun()}
            // onClick={handleResumeChat}
            disabled={resumeBtndisabled}
          >
            Resume Chat
          </Button>
        </div>
      );
    }

    return (
      <>
        {activeUser
          && activeUser?.messages
          && isChatEnabled && shouldShowButton(activeUser?.messages) ? (
            <div
              role="presentation"
              className="ar-whisperer-icon-wrapper"
              onClick={WhispererModalHandler}
            >
              <span className="ar-whisperer-tooltip">Request suggestions from Whisperer</span>
              <div className="ar-whisperer-icon-wrapper-inner">
                <img src={whispererBotNew} alt="Whisperer" />
              </div>
            </div>
          ) : isChatEnabled && (
            <></>
          )

        }
        <div className={onKeyPressInput || message.length > 0 ? 'type_msg type_msg-press' : 'type_msg'}>
          <div className="input_msg_write">
            <div className="input_msg_write_left_area">
              {
              isSnapPhotomodalOpen
                ? (
                  <CameraModal
                    modalOpen={isSnapPhotomodalOpen}
                    toggle={toggleCameraModal}
                    UploadImageFunction={onUploadSnap}
                  />
                )
                : ''
              }
              <textarea
                type="text"
                className={!isRecording ? 'write_msg' : 'write_msg write_msgRecording'}
                value={message}
                onChange={event => onMessageChange(event)}
                onKeyPress={event => onKeyPress(event)}
                readOnly={isRecording}
                style={{ width: isRecording && '150px', paddingTop: isRecording ? '9px' : '15px', resize: 'none' }}
                placeholder={!isRecording ? 'Type a message' : 'Recording...'}
              />
              {isRecording ? <span className="max-seconds">Max 30 seconds</span> : '' }
              <div
                style={{ width: isRecording ? '100%' : '0px' }}
                className="visualsWrapper"
              >
                <canvas
                  width={isRecording ? '280' : '0'}
                  height="30"
                  ref={visualizer}
                />
              </div>
            </div>
            <div className="input_msg_write_right_area">
              <div className="customer-option align-items-center">
                {isRecording
                && (
                <div className="recordingCounter">
                  {recordedSeconds > 9 ? recordedSeconds : `0${recordedSeconds}`}
                  s
                </div>
                )
                }
                <div id="microphone" className={`microphone ${isRecording ? 'recordingOn' : 'recordingOff'}`} style={{ cursor: 'pointer' }}>
                  {!isRecording ? (<div role="presentation" style={{ pointerEvents: !activeUser.objid ? 'none' : 'auto' }} ref={StartButton} onClick={isMinMsgsSent ? startRecording : undefined}><img src={microphone} alt="microphone" /></div>) : (<div role="presentation" style={{ pointerEvents: recordedSeconds > 3 ? 'auto' : 'none' }} ref={StopButton} onClick={() => stopRecording(true)}><img src={RecordingMic} alt="microphone" /></div>)}
                </div>
                <div className="upload-file mt-0 camera">
                  <div className="take-pic" id="camera_tooltip">
                    <button
                      type="button"
                      className="btn"
                      onClick={toggleCameraModal}
                      disabled={!activeUser.objid || isRecording || !isMinMsgsSent}
                    >
                      {cameraChat('#fff')}
                    </button>
                  </div>
                </div>
                <div className="upload-file  chooseImage">
                  <Label htmlFor="picFile" className="pic-file" id="gallery_tooltip">
                    {galleryChat('#fff')}
                  </Label>
                  <Button
                    type="button"
                    id="picFile"
                    className="file-input"
                    onClick={handleOnClickGallery}
                    disabled={!activeUser.objid || isRecording || !isMinMsgsSent}
                    style={!activeUser.objid ? { pointerEvents: 'none' } : {}}
                  />
                  {/* <Input
                    type="file"
                    id="picFile"
                    className="file-input"
                    accept="image/*"
                    disabled={!activeUser}
                    onChange={onUploadPicture}
                  /> */}
                </div>
                {!isMinMsgsSent
                  ? (
                    <>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen}
                        target="gallery_tooltip"
                        toggle={toggle}
                      >
                        <span>
                          {userMessage}
                        </span>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen2}
                        target="camera_tooltip"
                        toggle={toggle2}
                      >
                        <span>
                          {userMessage}
                        </span>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        isOpen={audioTooltip}
                        target="microphone"
                        toggle={toggleAudioTooltip}
                      >
                        <span>
                          {userMessage}
                        </span>
                      </Tooltip>
                    </>
                  ) : ''
                }

                <Button disabled={isShowMessageLengthError === true} onClick={() => sendMessageFunction('')} color="primary" className="sendButton p-0" type="button">
                  {sendChat('#fff')}
                </Button>
              </div>
            </div>
          </div>
          {errorMessage && <div id="char-count-error" className="chat-pref-msg p-0">{errorMessage}</div>}
          {
            isShowMessageLengthError
              ? <div className="chat-pref-msg p-0">{`Minimum message length should be ${minCharLength} character`}</div>
              : ''
          }
        </div>
      </>
    );
  };

  const handlePrivateMediaModal = () => {
    closeMediaPopup();
    setIsOpenPrivateMediaModal(!isOpenPrivateMediaModal);
  };

  const handleChatMediaModal = () => {
    closeMediaPopup();
    setIsOpenChatMediaModal(!isOpenChatMediaModal);
  };

  const getMediaPrivateModal = ({ media = '' }) => {
    const fileName = 'myfile.jpeg';
    if (media) {
      fetch(media)
        .then(async (response) => {
          // const contentType = response.headers.get('content-type');
          const blob = await response.blob();
          const file = new File([blob], fileName, {
            type: 'image/jpeg',
          });
          const newMediaObj = {
            text: '',
            mediaType: 'image/jpeg',
            media: file,
          };

          setSelectedMedia(newMediaObj);
          setIsOpenPrivateMediaModal(false);
          setIsOpenShareMediaModal(true);
        });
    }
  };

  const closeAlredaySharedModalPopup = () => {
    setIsOpenAlreadyShareMediaModal(false);
  };

  const yeshareMedia = (alreadySharedselectedMedia) => {
    if (alreadySharedselectedMedia) {
      const media = alreadySharedselectedMedia.product_thumb_url;
      const mediaTypes = alreadySharedselectedMedia.type;
      let mediaName = '';
      let mediaType = '';
      if (mediaTypes === 'chat_video') {
        mediaName = 'myvideo.mp4';
        mediaType = 'video/mp4';
      } else {
        mediaName = 'myphoto.jpeg';
        mediaType = 'image/jpeg';
      }
      if (media) {
        fetch(media)
          .then(async (response) => {
            const blob = await response.blob();
            const file = new File([blob], mediaName, {
              type: mediaName,
            });
            const updatedchatMediaVideoObj = {
              mediaType,
              media: file,
              productObjId: alreadySharedselectedMedia.objid,
              type: alreadySharedselectedMedia.type,
              photoUrl: alreadySharedselectedMedia.product_thumb_url,
              videoPrice: alreadySharedselectedMedia.price,
              videoId: alreadySharedselectedMedia.video_id,
            };
            setSelectedChatMedia(updatedchatMediaVideoObj);
            setIsOpenAlreadyShareMediaModal(false);
            setIsOpenChatMediaModal(false);
            setIsOpenChatMediaShareModal(true);
          });
      }
    }
  };

  const chatMediaSharedPhotosVideosHandler = (chatSharePhotosVideosMediaObj) => {
    if (chatSharePhotosVideosMediaObj) {
      const media = chatSharePhotosVideosMediaObj.product_thumb_url;
      const productID = chatSharePhotosVideosMediaObj.objid;
      const mediaTypes = chatSharePhotosVideosMediaObj.type;
      let mediaName = '';
      let mediaType = '';
      if (mediaTypes === 'chat_video') {
        mediaName = 'myvideo.mp4';
        mediaType = 'video/mp4';
      } else {
        mediaName = 'myphoto.jpeg';
        mediaType = 'image/jpeg';
      }
      const url = `api/perfcharacter/ismediashared/?product_id=${productID}&customer_id=${activeUser.custId}`;
      api.get(url)
        .then((responseData) => {
          const { result } = responseData.data;
          if (result) {
            setIsSelectAlreadySharedMedia(chatSharePhotosVideosMediaObj);
            setIsOpenAlreadyShareMediaModal(true);
          } else {
            console.log('mediaName', mediaName);
            if (media) {
              fetch(media)
                .then(async (response) => {
                  const blob = await response.blob();
                  const file = new File([blob], mediaName, {
                    type: mediaType,
                  });
                  const updatedChatMediaPhotoObj = {
                    mediaType,
                    media: file,
                    productObjId: chatSharePhotosVideosMediaObj.objid,
                    type: chatSharePhotosVideosMediaObj.type,
                    photoUrl: chatSharePhotosVideosMediaObj.product_thumb_url,
                    photoPrice: chatSharePhotosVideosMediaObj.price,
                    videoId: chatSharePhotosVideosMediaObj.video_id,
                  };
                  setSelectedChatMedia(updatedChatMediaPhotoObj);
                  setIsOpenChatMediaModal(false);
                  setIsOpenChatMediaShareModal(true);
                });
            }
          }
        })
        .catch(errors => console.log(errors));
    }
  };
  const renderCustomerReviewModal = () => (
    <CustomerReviewModal
      header="Rate Customer"
      open={isCustomerReviewModalOpen[activeUser?.custId]?.showModal || false}
      toggle={() => {
        setIsCustomerReviewModalOpen(prevStates => ({
          ...prevStates,
          [activeUser?.custId]: { showModal: false },
        }));
      }}
      activeUser={activeUser}
      onClickFun={onCustomerReviewSubmit}
    />
  );

  // if (customerReviewForm === false) {
  //   setIsCustomerReviewModalOpen(true);
  // }

  const renderMediaPopup = () => {
    if (isShowMediaOptionModal) {
      return (
        <MediaOptionModal
          isOpen
          onUploadPicture={onUploadPicture}
          closeMediaPopup={closeMediaPopup}
          handlePrivateMediaModal={handlePrivateMediaModal}
          handleChatMediaModal={handleChatMediaModal}
          getChatMediaPhotosFun={getChatMediaPhotosFun}
          getChatMediaVideosFun={getChatMediaVideosFun}
        />
      );
    }
    return '';
  };

  const renderPrivateMediaPopup = () => {
    if (isOpenPrivateMediaModal) {
      return (
        <PrivateMediaOptionModal
          isOpen
          handlePrivateMediaModal={handlePrivateMediaModal}
          getPrivateMedia={getPrivateMedia}
          privateProductList={privateProductList}
          next={next}
          privateMediaCount={privateMediaCount}
          sendMessage={sendMessage}
          getMediaPrivateModal={getMediaPrivateModal}
        />
      );
    }
    return '';
  };

  const renderChatMediaPopup = () => {
    if (isOpenChatMediaModal) {
      return (
        <ChatMediaOptionModal
          isOpen
          handleChatMediaModal={handleChatMediaModal}
          chatMediaProductList={privateProductList}
          chatMediaSharedPhotosVideosHandler={chatMediaSharedPhotosVideosHandler}
          getChatMediaPhotos={getChatMediaPhotosState}
          getChatMediaVideos={getChatMediaVideosState}
        />
      );
    }
    return '';
  };

  const renderChatMediaPopupModal = () => {
    if (isMediaModal) {
      return (
        <MediaModal
          isOpen
          handleChatMediaModal={handleChatMediaModal}
          chatMediaProductList={privateProductList}
          chatMediaSharedPhotosVideosHandler={chatMediaSharedPhotosVideosHandler}
          getChatMediaPhotos={sharedPhotos}
          getChatMediaVideos={sharedVideos}
          setIsMediaModal={setIsMediaModal}
          setSharedPhotos={setSharedPhotos}
          setSharedVideos={setSharedVideos}

        />
      );
    }
    return '';
  };

  const renderShareMediaPopup = () => {
    if (isOpenShareMediaModal) {
      return (
        <ShareMediaOptionModal
          isOpen
          closeShareMediaPopup={closeShareMediaPopup}
          sendMedia={sendMedia}
          selectedMedia={selectedMedia}
          minImagePurchaseCredits={minImagePurchaseCredits}
          minVideoPurchaseCredits={minVideoPurchaseCredits}
          minAudioPurchaseCredits={minAudioPurchaseCredits}
          performerMediaPrices={performerMediaPrices}
          videoDuration={videoDuration}
        />
      );
    }
    return '';
  };

  const getSharedMedia = async () => {
    try {
      const url = `/chatmsg/host_media/?character=${charObjid}&customer=${newActiveUserId?.custId || activeUser.custId}`;
      const { data } = await api.get(url);
      const allPhotos = [];
      const allVideos = [];
      if (data.results.length > 0) {
        data.results.map((obj) => {
          if (obj.type === 'chat_image') {
            allPhotos.push(obj);
          } else {
            allVideos.push(obj);
          }
          return false;
        });
        setSharedPhotos(allPhotos);
        setSharedVideos(allVideos);
      } else {
        setSharedPhotos(data.results);
        setSharedVideos(data.results);
      }
    } catch (error) {
      console.log({ error });
    }
    return false;
  };

  const renderAlreadySharedMediaModal = () => {
    if (isOpenAlreadyShareMediaModal) {
      return (
        <AlreadySharedMediaModal
          isOpen
          isSelectAlreadySharedMedia={isSelectAlreadySharedMedia}
          yeshareMedia={yeshareMedia}
          closeAlredaySharedModalPopup={closeAlredaySharedModalPopup}
        />
      );
    }
    return '';
  };
  const MediaHandler = () => {
    closeMediaPopup();
    getSharedMedia();
    setIsMediaModal(true);
  };

  const PremiumChatHandler = (value) => {
    setPremiumChatStatus(state => !state);
    handlePremiumChat(value);
  };

  const fetchNotes = async (setValue) => {
    try {
      const { data } = await api.get(`/api/cstnotes/list/?character_objid=${charObjid}&cst_contact_objid=${activeUser?.custId}`);
      setValue(data?.results[0].note || '');
    } catch (error) {
      console.log(error);
    }
  };

  const SaveNotesHandler = async (Notes, status) => {
    const formData = new FormData();
    formData.append('character_objid', charObjid);
    formData.append('cst_contact_objid', activeUser?.custId);
    formData.append('note', Notes);
    // formData.append('note_type', 'Chat_Notes');
    try {
      const { data } = await api.put('/cstnotes/update/', formData);
      status({
        status: 'success',
        message: 'Updated the Notes Successfully',
      });
      if (toastID) {
        toastID = toast.update(toastID, {
          type: toast.TYPE.SUCCESS,
          render: 'Updated the Notes Successfully',
        });
      }
      toastID = toast.success('Updated the Notes Successfully');
      setOpenNotesModal(false);
    } catch (error) {
      status({
        status: 'error',
        message: error?.response?.data?.message || 'Something went wrong. Unable to save the notes',
      });
      if (toastID) {
        toastID = toast.update(toastID, { type: toast.TYPE.ERROR, render: error?.response?.data?.message || 'Something went wrong. Unable to save the notes' });
      }
      toast.error(error?.response?.data?.message || 'Something went wrong. Unable to save the notes');
    }
  };

  const renderNotesModal = () => {
    if (openNotesModal) {
      return (
        <NotesModal
          setOpenNotesModal={setOpenNotesModal}
          isOpen={openNotesModal}
          SaveNotesHandler={SaveNotesHandler}
          fetchNotes={fetchNotes}
        />
      );
    }
    return '';
  };

  const renderChatMediaSharePopup = () => {
    if (isOpenChatMediaShareModal) {
      return (
        <ChatMediaShareOptionModal
          isOpen
          closeChatMediaShareModal={closeChatMediaShareModal}
          selectedChatMedia={selectedChatMedia}
          sendMessage={sendMessage}
          minImagePurchaseCredits={minImagePurchaseCredits}
          minVideoPurchaseCredits={minVideoPurchaseCredits}
          performerMediaPrices={performerMediaPrices}

        />
      );
    }
    return '';
  };

  const NotesHandler = async () => {
    setOpenNotesModal(true);
  };

  const onlyRenderDropDown = () => {
    if (!isActiveForChat || activeUser?.id === 0) {
      return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} className="ellipsis-menu">
          <DropdownToggle caret>
            <i className="fa fa-ellipsis-v" aria-hidden="true" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem className="none-in-mobile" onClick={() => setReportMemberEndSessionModalOpen(true)}>Report user</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      );
    }

    if (activeUser?.sessionStatus === 'off' || floodedMsg) {
      return (
        <div className="ar-chat-head-right-action">
          <div>
            {isFav ? (
              <div className="fav-icon-wrap-call-chat enable-fav" onClick={() => handleFavUnFavIconClick(false)} role="presentation">
                <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
                </svg>
              </div>
            ) : (
              <div className="fav-icon-wrap-call-chat disable-fav" onClick={() => handleFavUnFavIconClick(true)} role="presentation">
                <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
                </svg>
              </div>
            )
            }
          </div>
          <div onClick={MediaHandler} role="presentation">
            <img src={Media} alt="Media Gallery" />
          </div>
          <div onClick={NotesHandler} role="presentation">
            <img src={NotesIcon} alt="Customer Notes" />
          </div>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} className="ellipsis-menu">
            <DropdownToggle caret>
              <i className="fa fa-ellipsis-v" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="none-in-mobile" onClick={() => setReportMemberEndSessionModalOpen(true)}>Report user</DropdownItem>
              <DropdownItem onClick={() => setBlockMemberModalOpen(true)}>Block User</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

      );
    }
    return (
      <>
        {/* <SwitchButton
          onChangeFunction={handlePremiumChat}
          value={activeUser?.premium}
          style={{ right: '80px' }}
        /> */}
        <div className="ar-chat-head-right-action">
          <div>
            {isFav ? (
              <div className="fav-icon-wrap-call-chat enable-fav" onClick={() => handleFavUnFavIconClick(false)} role="presentation">
                <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
                </svg>
              </div>
            ) : (
              <div className="fav-icon-wrap-call-chat disable-fav" onClick={() => handleFavUnFavIconClick(true)} role="presentation">
                <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
                </svg>
              </div>
            )
            }
          </div>
          <div
            onClick={() => PremiumChatHandler(!premiumChatStatus)}
            className={premiumChatStatus ? 'ActivePremiumChat' : ''}
            role="presentation"
          >
            { premiumChatStatus ? <img src={DollarActiveIcon} alt="Premium Chat" /> : <img src={DollarInactiveIcon} alt="Premium Chat" />}
          </div>
          <div onClick={MediaHandler} role="presentation">
            <img src={Media} alt="Media Gallery" />
          </div>
          <div onClick={NotesHandler} role="presentation">
            <img src={NotesIcon} alt="Customer Notes" />
          </div>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} className="ellipsis-menu">
            <DropdownToggle caret>
              <i className="fa fa-ellipsis-v" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={endSession}>End session</DropdownItem>
              <DropdownItem className="none-in-mobile" onClick={redirectToSupport}>Report a bug</DropdownItem>
              <DropdownItem className="none-in-mobile" onClick={() => setReportMemberModalOpen(true)}>Report user</DropdownItem>
              <DropdownItem onClick={() => setBlockMemberModalOpen(true)}>Block User</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </>
    );
  };

  const copyToTextAreaHandler = (value, callback, trackerFunc, chatBotMsgID) => {
    if (chatBotMsgID) {
      localStorage.setItem('chatBotMsgID', chatBotMsgID);
    } else {
      localStorage.setItem('chatBotMsgID', '');
    }
    setMessage(value);
    trackerFunc();
    setWhispererModal(false);
    // Commenting the code to close the modal function to avoid multiple API calls
    // callback();
  };

  const sendWhispererMsgHandler = (value, callback, trackerFunc1,
    sendMessageDirectly = false, chatBotMsgID) => {
    if (value && value.length >= minCharLength && sendMessageDirectly) {
      sendMessage({ text: value, media: '', chatBotMsgID });
      setShowMessageLengthError(false);
    } else {
      setShowMessageLengthError(true);
      setMessage(value);
    }
    trackerFunc1();
    setWhispererModal(false);
    // Commenting the code to close the modal function to avoid multiple API calls
    // callback();
  };

  const renderDropDown = () => {
    const renderBlockMemberModal = () => (
      <CustomModal
        header="Block Member"
        body="Do you really want to block this customer?"
        buttonText="Submit"
        open={BlockMemberModalOpen}
        onClick={() => blockCustomer('#BAN')}
        toggle={() => setBlockMemberModalOpen(false)}
      />
    );

    const renderReportMemberModal = () => (
      <CustomModal
        header="Report Member"
        buttonText="Submit"
        isRadioButton
        htmlElements={reportMemberRadioButtons}
        open={ReportMemberModalOpen}
        onClick={() => blockCustomer(reportDataValue)}
        toggle={() => setReportMemberModalOpen(false)}
      />
    );

    const renderReportMemberEndSessionModal = () => (
      <CustomModal
        header="Report Member End session"
        buttonText="Submit"
        isRadioButton
        htmlElements={reportMemberRadioButtons}
        open={reportMemberEndSessionModalOpen}
        onClick={() => blockEndedSessionCustomer(reportDataValue)}
        toggle={() => setReportMemberEndSessionModalOpen(false)}
      />
    );

    return (
      <>
        {renderReportMemberEndSessionModal()}
        {renderBlockMemberModal()}
        {renderReportMemberModal()}
        {renderChatMediaPopup()}
        {onlyRenderDropDown()}
      </>
    );
  };

  return (
    <React.Fragment>
      {renderMediaPopup()}
      {renderPrivateMediaPopup()}
      {renderShareMediaPopup()}
      {renderChatMediaSharePopup()}
      {renderCustomerReviewModal()}
      {renderChatMediaPopupModal()}
      {renderAlreadySharedMediaModal()}
      {renderNotesModal()}
      {}
      {whispererModal ? <WhispererModal sendWhispererMsgHandler={sendWhispererMsgHandler} copyToTextAreaHandler={copyToTextAreaHandler} activeUser={activeUser} setWhispererModal={setWhispererModal} whispererModal={setWhispererModal} /> : ''}
      <ToastContainer />
      <Card className="ChatSection ChatSectionMsgArea  bg-dark border-0 ml-1 h-100">
        {activeUser && activeUser.title ? (
          <>
            <CardHeader className="conversion-header d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center host-dp w-100">
                <div className="ar-chat-head-left-info">
                  <button type="button" className="back_btn" onClick={onChatBackHandler}>
                    {backButtonIcon()}
                  </button>
                  <div className="image-wrap">
                    <div className="img_with_crown">
                      {activeUser.custPic && activeUser.custPic !== 'None'
                        ? <img className="rounded-circle" width="65" height="65" src={activeUser.custPic} alt="cuspic" />
                        : <svg className="avatar-image" width="65" height="65" data-jdenticon-value="icon value" />}
                      {activeUser && activeUser.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
                      {showOnlineStatus(onlineStatusHandler(activeUser),
                        activeUser.sendEmailNotification)}
                    </div>
                  </div>
                  <div className="rating-wrap">
                    <div className="online-user online-user-chat">
                      <span className="user-name">{activeUser && activeUser.title}</span>
                      {activeUser ? (
                        <div className="chat-customer-review chat-customer-chat">
                          <div className="chat-reviews">
                            <div className="bar-seprate-line-wrap">
                              <span className="bar-seprate-line bar-line-1" />
                              <span className="bar-seprate-line bar-line-2" />
                              <span className="bar-seprate-line bar-line-3" />
                              <span className="bar-seprate-line bar-line-4" />
                            </div>
                            <SemiCircleProgressBar
                              percentage={
                                (activeUser.customerHostReview
                                  && activeUser.customerHostReview.creative * 20)
                                || (newReviewObj && newReviewObj.creative * 20)
                              }
                              strokeWidth={7}
                              diameter={50}
                              stroke="#ff7167"
                              background="#3E3E3E"
                            />
                            <div className="title_container">
                              <span className="rating_number">
                                {(activeUser.customerHostReview
                                  && activeUser.customerHostReview.creative)
                                || (newReviewObj && newReviewObj.creative)}
                              </span>
                              <span className="rating_title">Creativity</span>
                            </div>
                          </div>
                          <div className="chat-reviews">
                            <div className="bar-seprate-line-wrap">
                              <span className="bar-seprate-line bar-line-1" />
                              <span className="bar-seprate-line bar-line-2" />
                              <span className="bar-seprate-line bar-line-3" />
                              <span className="bar-seprate-line bar-line-4" />
                            </div>
                            <SemiCircleProgressBar
                              percentage={
                                (activeUser.customerHostReview
                                  && activeUser.customerHostReview.sexy * 20)
                                || (newReviewObj && newReviewObj.sexy * 20)
                              }
                              strokeWidth={7}
                              diameter={50}
                              stroke="#ff7167"
                              background="#3E3E3E"
                            />
                            <div className="title_container">
                              <span className="rating_number">
                                {(activeUser.customerHostReview
                                  && activeUser.customerHostReview.sexy)
                                || (newReviewObj && newReviewObj.sexy)}
                              </span>
                              <span className="rating_title">Sexiness</span>
                            </div>
                          </div>
                          <div className="chat-reviews">
                            <div className="bar-seprate-line-wrap">
                              <span className="bar-seprate-line bar-line-1" />
                              <span className="bar-seprate-line bar-line-2" />
                              <span className="bar-seprate-line bar-line-3" />
                              <span className="bar-seprate-line bar-line-4" />
                            </div>
                            <SemiCircleProgressBar
                              percentage={
                                (activeUser.customerHostReview
                                  && activeUser.customerHostReview.generous * 20)
                                || (newReviewObj && newReviewObj.generous * 20)
                              }
                              strokeWidth={7}
                              diameter={50}
                              stroke="#ff7167"
                              background="#3E3E3E"
                            />
                            <div className="title_container">
                              <span className="rating_number">
                                {(activeUser.customerHostReview
                                  && activeUser.customerHostReview.generous)
                                || (newReviewObj && newReviewObj.generous)}
                              </span>
                              <span className="rating_title">Generosity</span>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                    </div>
                  </div>
                </div>
                {renderDropDown()}
              </div>
            </CardHeader>

            {chatMsgLoading && activeUser.messages.length === 0 ? <LoadingSpinner />
              : emojis.length ? (
                <CardBody className="write-footer pb-0 position-relative overflow-hidden">
                  {emojis.length && (
                  <Conversation
                    messages={activeUser && activeUser.messages}
                    emojis={emojis}
                    activeUserId={activeSessionID || activeUser.objid}
                    isActiveSession={activeUser.sessionStatus === 'on'}
                    isRoomToggled={isRoomToggled}
                    sendMessage={sendMessage}
                    SubmitForwardRequest={SubmitForwardRequest}
                    fetchMoreData={fetchMoreData}
                    hasMore={hasMore}
                    infiniteloading={infiniteloading}
                  />
                  )}

                  {msgFeildRender()}
                </CardBody>
              )
                : <LoadingSpinner />}
          </>
        ) : (
          <IntialScreen />
        )}
      </Card>
    </React.Fragment>
  );
};

AllConversation.defaultProps = {
  activeUser: {},
  chatValidationData: {},
  privateProductList: [],
  next: null,
  privateMediaCount: 0,
  chatMsgLoading: false,
  minImagePurchaseCredits: '',
  minVideoPurchaseCredits: '',
  minAudioPurchaseCredits: '',
  performerMediaPrices: '',
  resumeBtndisabled: false,
  getChatMediaPhotosState: [],
  getChatMediaVideosState: [],
  isRoomToggled: {},
  activeUserOnlineStatus: false,
  charObjid: '',
  newActiveUserId: {},
  fetchMoreData: () => {},
  isSessionExpired: false,
};

AllConversation.propTypes = {
  activeUser: PropTypes.instanceOf(Object),
  sendMessage: PropTypes.func.isRequired,
  sendMedia: PropTypes.func.isRequired,
  chatValidationData: PropTypes.instanceOf(Object),
  getPrivateMedia: PropTypes.func.isRequired,
  privateProductList: PropTypes.instanceOf(Array),
  next: PropTypes.string,
  privateMediaCount: PropTypes.bool,
  chatMsgLoading: PropTypes.bool,
  onChatBackHandler: PropTypes.func.isRequired,
  minImagePurchaseCredits: PropTypes.string,
  minVideoPurchaseCredits: PropTypes.string,
  minAudioPurchaseCredits: PropTypes.string,
  performerMediaPrices: PropTypes.string,
  checkActiveSessionFun: PropTypes.func.isRequired,
  resumeBtndisabled: PropTypes.bool,
  getChatMediaPhotosState: PropTypes.instanceOf(Array),
  getChatMediaVideosState: PropTypes.instanceOf(Array),
  getChatMediaPhotosFun: PropTypes.func.isRequired,
  getChatMediaVideosFun: PropTypes.func.isRequired,
  createCustomerReviewFun: PropTypes.func.isRequired,
  isRoomToggled: PropTypes.instanceOf(Object),
  activeUserOnlineStatus: PropTypes.bool,
  blockedInactiveSessionCustomer: PropTypes.func.isRequired,
  charObjid: PropTypes.string,
  newActiveUserId: PropTypes.instanceOf(Object),
  fetchMoreData: PropTypes.func,
  isSessionExpired: PropTypes.bool,
};

export default AllConversation;
