import React, { useEffect, useState } from 'react';
import {
  Card, CardBody,
  CardHeader, Col, Row,
  Tooltip,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import NoDataAvailable from '../../components/NoDataAvailable';
import CreditsContainer from '../../containers/CreditsContainer';
import AffiliateCreditsCard from './component/AffiliateCreditCard';
import LoadingSpinner from '../../utils/LoadingSpinner';
import affiliateLink from '../../assets/images/Affliate_Earnings.png';
import copy from '../../assets/svgs/copy.svg';
import './style.scss';

const Affiliate = (props) => {
  const pageSize = 8; // No. of entries in Table.
  const [pageNumber, setpageNumber] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const {
    getTransactionTableData,
    updatedTranTableDate,
    getAffliateLink,
    getAffiliateConfig,
    getPerformer,
    affLinkData: { affiliationLink, shortURL },
    count,
    cstObjectId,
    loadingData,
    affiConfig,
    history,
    perfObjid,
  } = props;

  const {
    firstPurchasebonus, joinBonus, revShareBonus,
    fpbDollar, jbDollar, isShowPayout,
  } = affiConfig;

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    const linkURL = `/afflurl/list/?contact_objid=${cstObjectId}`;
    const configURL = `/cstaffiliateconfig/list/?affiliate2cst_contact_objid=${cstObjectId}`;
    getAffliateLink(linkURL);
    getAffiliateConfig(configURL);
    getPerformer(perfObjid);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const url = `/cfgcredittransactions/list/?performer2cst_contact=${cstObjectId}&trans_type=BON003&page_size=${pageSize}&page=${pageNumber}`;
    getTransactionTableData(url);
    setcurrentPage(pageNumber);
  }, [pageNumber]);

  const dateFormatter = cell => moment(new Date(cell)).format('YYYY-MM-DD hh:mm:ss');

  const typeFormatter = (type) => {
    const displayType = {
      'hard conversion bonus': 'First Purchase',
      'soft conversion bonus': 'Free Join',
      'repurchase conversion bonus': 'Revshare',
    };
    return (
      <div className="screen-name">
        <span>{displayType[type]}</span>
      </div>
    );
  };

  const handlePagination = (pageNo = 1) => {
    setpageNumber(pageNo);
  };

  const copyToClipBoard = () => {
    const copyText = document.getElementById('copyInput');
    copyText.select();
    document.execCommand('copy');
  };

  const columns = [{
    dataField: 'trans_datetime',
    formatter: dateFormatter,
    text: 'Date',
    sort: true,
  }, {
    dataField: 'trans_comment',
    formatter: typeFormatter,
    text: 'Type',
    sort: true,
  }, {
    dataField: 'performer_revenue_credit',
    text: 'Credits',
    sort: true,
  }];

  const renderTransactionTable = () => {
    if (loadingData) {
      return (
        <div style={{ height: '400px' }}>
          <LoadingSpinner />
        </div>
      );
    }

    if (updatedTranTableDate.length <= 0) {
      return (
        <div style={{ height: '400px' }}>
          <NoDataAvailable />
        </div>
      );
    }

    return (
      <BootstrapTable
        striped
        bootstrap4
        bordered={false}
        keyField="trans_id"
        data={updatedTranTableDate}
        columns={columns}
      />
    );
  };

  return (
    <Row className="rowspacing-20 mt-3">
      <Col md={6}>
        <Card className="bg-dark h-100">
          <CardHeader className="pl-4 pb-0 border-0">Transaction History</CardHeader>
          <CardBody>
            {renderTransactionTable()}
            {count > 0 && (
              <div className="pull-right">
                <Pagination
                  totalCount={count}
                  pageSize={pageSize}
                  onPageClick={handlePagination}
                  currentPage={currentPage}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <CreditsContainer
          component={AffiliateCreditsCard}
          history={history}
        />
        <Row>
          <Col xs={12}>
            <Card className="bg-dark">
              <CardBody>
                <div className="aff-card-heading">Your Personal Affiliate Link</div>
                <div className="aff-image" />
                <figure className="text-center mt-4">
                  <img src={affiliateLink} alt="" className="img-fluid" />
                </figure>
                <div className="text-center sendText">
                  Send Fans to arousr and earn Money
                  <span id="affiliateInfo">?</span>
                </div>
                <div className="text-center linkText mt-2">
                  {'Your Affiliate Link: '}
                  <a href={affiliationLink} target="_blank" rel="noopener noreferrer">
                    {shortURL}
                  </a>
                  <label htmlFor="copyInput">
                    <input type="text" value={affiliationLink} id="copyInput" readOnly style={{ position: 'fixed', left: '-9999px' }} />
                    <button type="button" onClick={copyToClipBoard} className="p-0 bg-none border-0 ml-1">
                      <img src={copy} alt="copy" width="11" className="img-fluid" />
                    </button>
                  </label>
                </div>
                <Tooltip
                  placement="right-end"
                  isOpen={tooltipOpen}
                  target="affiliateInfo"
                  toggle={toggle}
                  popperClassName="popperClassName"
                  innerClassName="innerClassName"
                  arrowClassName="arrowClassName"
                  className="opacity"
                >
                  <div className="bg-dark p-3 quetionHiddenWrp">
                    <ul>
                      <li>
                        <span>
                          This is your personal Affiliate Link.  When a user clicks
                          it, he will be sent to your Profile Page.  From that point,
                          if he joins for a free account you will earn Money.  You
                          will earn commissions even if you don&apos;t chat with
                          the user!
                        </span>
                      </li>
                    </ul>
                    {isShowPayout ? (
                      <h3 className="payout">
                        Payout
                      </h3>
                    ) : ''}
                    <ul>
                      {joinBonus ? (
                        <li className="text-left">
                          <span>
                            {`Free account opening ${joinBonus} credits ($${jbDollar})`}
                          </span>
                        </li>
                      ) : ''
                      }
                      {firstPurchasebonus ? (
                        <li className="text-left">
                          <span>
                            {`First real purchase ${firstPurchasebonus} credits ($${fpbDollar})`}
                          </span>
                        </li>
                      ) : ''
                      }
                      {revShareBonus ? (
                        <li className="text-left">
                          <span>
                            {revShareBonus ? `Revenue Share ${revShareBonus}% of Credit Sales` : ''}
                          </span>
                        </li>
                      ) : ''
                      }
                    </ul>
                    <p className="supportText mb-0">
                    Questions? Contact Support.
                    </p>
                  </div>
                </Tooltip>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Affiliate.defaultProps = {
  updatedTranTableDate: [],
  count: 0,
  cstObjectId: 0,
  affLinkData: {},
  loadingData: false,
  affiConfig: {},
  perfObjid: -1,
};

Affiliate.propTypes = {
  getTransactionTableData: PropTypes.func.isRequired,
  updatedTranTableDate: PropTypes.instanceOf(Array),
  count: PropTypes.number,
  cstObjectId: PropTypes.number,
  affLinkData: PropTypes.shape({
    affiliationLink: PropTypes.string,
    shortURL: PropTypes.string,
  }),
  loadingData: PropTypes.bool,
  getAffliateLink: PropTypes.func.isRequired,
  getAffiliateConfig: PropTypes.func.isRequired,
  affiConfig: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  getPerformer: PropTypes.func.isRequired,
  perfObjid: PropTypes.number,
};

export default Affiliate;
