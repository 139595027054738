import React from 'react';

import companyLogo from '../../assets/images/logo.png';
import NoMobileImg from '../../assets/images/NoMobile.svg';
import './style.scss';

const NotForMobile = () => (
  <>
    <header className="header__not_for_mobile">
      <img src={companyLogo} alt="arousr" />
    </header>
    <main className="main__not_for_mobile">
      <img src={NoMobileImg} alt="no mobile" />
      <aside className="text__not_for_mobile">
        {"This website can't be accessed on Mobile - Please use the app instead. Watch this video for instructions"}
      </aside>
    </main>
    <div className="use_app_video_wrapper">
      <iframe
        width="260"
        height="115"
        className="use_app_video"
        src="https://www.youtube.com/embed/FLyIfhGlhc8"
        title="how to use app"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="1"
      />
    </div>
  </>
);

export default NotForMobile;
