/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from 'react';
import './style.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from '../../../../../actions/api';

// // Fetch emojis from the API when the component mounts
// useEffect(() => {
//   if (emojis && !emojis.length) {
//     api.post('api/chatmsgemoji/create/', {
//       chatmsg,
//       emoji,
//       session_objid,
//     })
//       .then(response => response.data)
//       .then((data) => {
//         console.log(data.results, 'data');
//         setEmojis(data.results); // API returns an array of emoji objects
//       })
//       .catch((error) => {
//         console.error('Error fetching emojis:', error);
//       });
//   }
// }, [emojis]);

const EmojiList = ({
  // eslint-disable-next-line react/prop-types
  emojis, onSelectedEmoji, activeUserId, msgId,
}) => {
  const EmojiSelector = async (emoji) => {
    console.log({ activeUserId, msgId, emoji });
    try {
      const formData = new FormData();
      formData.append('chatmsg', msgId);
      formData.append('emoji', emoji.objid);
      formData.append('session_objid', activeUserId && activeUserId);
      const { data } = await api.post('/chatmsgemoji/create/', formData, {
        // headers: {
        //   'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
        // },
      });
      const selectedEmoji = emojis.find(em => em.objid === data.emoji);
      onSelectedEmoji(selectedEmoji);
    } catch (error) {
      onSelectedEmoji(emoji);
      console.error('Error fetching emojis:', error);
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 3,
    arrow: true,
  };
  return (
    <div className="EmojiSlider">
      <Slider {...settings}>
        {emojis && emojis.map(emoji => <div role="presentation" onClick={() => EmojiSelector(emoji)} key={emoji.id}><img className="emojiListImgs" src={emoji.image_path} alt="emoji" title={emoji.title} /></div>)}
      </Slider>
    </div>
  );
};

export default EmojiList;
