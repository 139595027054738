import React, { useState, useEffect } from 'react';
import {
  Button, Card, CardBody,
  CardFooter,
  CardHeader, Col, Input, Row, ListGroup, ListGroupItem, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../utils/LoadingSpinner';

import './style.scss';
import closeIcon from '../../assets/svgs/close-icon';

const Visibility = (props) => {
  const {
    actionExcludedRegionList,
    deleteCountry,
    updateExcludedList,
    perfcharacterObjid,
    excludedList: excludedRegionList,
    tagListLoading,
    getRegionList,
    regionList,
    countryStateListLoading,
    addExcludeRegion,
  } = props;
  const [isListOpen, setListOpen] = useState(false);
  const [excludedTagList, setExcludedTagList] = useState([]);
  const [formatedOptionList, setFormatedOptionList] = useState([]);
  const [regionOptionList, setregionOptionList] = useState([]);
  const [isShowError, setIsShowError] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState([]);
  // const [addNewRegionId, setaddNewRegionId] = useState(0);
  // const [deleteNewRegionId, setdeleteNewRegionId] = useState(0);

  // get List of excluded region
  useEffect(() => {
    const data = `/perfcharactergroup/list/?objid2perf_character=${perfcharacterObjid}&exclusions=${true}&excluded_only=${true}`;
    if (perfcharacterObjid) {
      actionExcludedRegionList(data);
    }
    window.scrollTo(0, 0);
  }, []);

  // delete indivisual excluded region
  const deleteRegion = (regionId = 0) => {
    const updatedregionsList = excludedRegionList.filter(region => (
      region.cfggroup_objid !== regionId
    ));
    if (regionId) {
      const id = 0;
      const url = `/perfcharactergroup/delete/${id}/?objid2perf_character=${perfcharacterObjid}&objid2cfg_group=${regionId}`;
      deleteCountry(url);
      updateExcludedList(updatedregionsList);
    }
  };

  // create tag from excluded list
  useEffect(() => {
    const tagsList = excludedRegionList.map(region => (
      <div key={`tag_${region.cfggroup_objid}`} className="exc-cntry-tag">
        <div className="bg-dark innerTag">
          <span>{region.region_name}</span>
          <span aria-hidden="true" className="cursorPointer" onClick={() => deleteRegion(region.cfggroup_objid)}>{closeIcon('#5D6569')}</span>
        </div>
      </div>
    ));
    setExcludedTagList(tagsList);
  }, [excludedRegionList]);

  // update state list from props
  useEffect(() => {
    setregionOptionList(regionList);
  }, [regionList]);

  // -----------Work on Hide me region list start-----------

  // change on check box in region list
  const excludeRegion = (event, regionId) => {
    let updatedList = [];
    setIsShowError(false);
    if (event?.target?.checked) {
      const selectList = [...selectedRegion];
      selectList.push(regionId);
      setSelectedRegion(selectList);
      updatedList = regionOptionList.map((region) => {
        if (region.cfggroup_objid === regionId) {
          return {
            ...region,
            isChecked: true,
          };
        }
        return region;
      });
    } else {
      const selectList = [...selectedRegion];
      const eleIndex = selectList.findIndex(eleId => eleId === regionId);
      selectList.splice(eleIndex, 1);
      setSelectedRegion(selectList);
      updatedList = regionOptionList.map((region) => {
        if (region.cfggroup_objid === regionId) {
          return {
            ...region,
            isChecked: false,
          };
        }
        return region;
      });
    }
    setregionOptionList(updatedList);
  };

  // create List after click on hide me from region list api
  useEffect(() => {
    const countryStateOption = regionOptionList.map((region) => {
      const { region_name: regionName, cfggroup_objid: cfgObjid, isChecked = false } = region;
      return (
        <ListGroupItem className="" key={`Option${cfgObjid}`}>
          <div className="customCheckbox">
            <Input type="checkbox" checked={isChecked} id={`regionOption${cfgObjid}`} aria-label="Checkbox for input" onChange={event => excludeRegion(event, cfgObjid)} />
            <Label htmlFor={`regionOption${cfgObjid}`}>{regionName}</Label>
          </div>
        </ListGroupItem>
      );
    });
    setFormatedOptionList(countryStateOption);
  }, [regionOptionList]);

  // api call on hide me region list open
  useEffect(() => {
    if (isListOpen) {
      const url = `/perfcharactergroup/list/?objid2perf_character=${perfcharacterObjid}&exclusions=${true}&excluded_only=${false}`;
      getRegionList(url);
    }
  }, [isListOpen]);

  // toggle region list click on hide me on region button
  const openCountriesList = () => {
    setIsShowError(false);
    setListOpen(!isListOpen);
  };

  // search implementation in list
  const handleSearch = (event) => {
    let searchInput = event?.target?.value;
    if (searchInput) {
      const searchUpdatedList = regionList?.filter((region) => {
        const { region_name: regionName } = region;
        const regionNameWithoutSpace = regionName.toLowerCase().replace(/ /g, '');
        searchInput = searchInput.toLowerCase().replace(/ /g, '');
        return regionNameWithoutSpace.includes(searchInput);
      });
      const updatedSearchList = searchUpdatedList.map((region) => {
        if (selectedRegion.indexOf(region.cfggroup_objid) >= 0) {
          return {
            ...region,
            isChecked: true,
          };
        }
        return region;
      });
      setregionOptionList(updatedSearchList);
    }
  };

  // after select Region click on Done for exclude Region
  const excludeCountry = () => {
    // const newRegionAddList = [];
    // regionOptionList.forEach((region) => {
    //   const { isChecked, cfggroup_objid: cfgGrpId } = region;
    //   if (isChecked) {
    //     newRegionAddList.push(cfgGrpId);
    //   }
    // });
    const addCountryGrp = selectedRegion.join();
    const data = {
      objid2perf_character: perfcharacterObjid,
      objid2cfg_group: addCountryGrp,
    };
    if (addCountryGrp) {
      addExcludeRegion(data).then(() => {
        const url = `/perfcharactergroup/list/?objid2perf_character=${perfcharacterObjid}&exclusions=${true}&excluded_only=${true}`;
        actionExcludedRegionList(url).then(() => {
          setSelectedRegion([]);
        });
      });
      setListOpen(false);
    } else {
      setIsShowError(true);
    }
  };

  // handle select all
  const handleSelectAll = (event) => {
    let updatedOptionList = [];
    let list = [];
    setIsShowError(false);
    if (event?.target?.checked) {
      updatedOptionList = regionOptionList.map(region => ({
        ...region,
        isChecked: true,
      }));
      updatedOptionList.forEach((region) => {
        if (region.isChecked) {
          list.push(region.cfggroup_objid);
        }
      });
    } else {
      updatedOptionList = regionOptionList.map(region => ({
        ...region,
        isChecked: false,
      }));
      list = [];
    }
    setSelectedRegion(list);
    setregionOptionList(updatedOptionList);
  };

  // -----------Work on Hide me region list end-----------

  // clear all Exclude Region
  const removeAllExcudedRegion = () => {
    const removeRegionGrp = excludedRegionList.map(region => (region.cfggroup_objid));
    const strRemoveRegionGrp = removeRegionGrp.join();
    if (strRemoveRegionGrp) {
      const url = `/perfcharactergroup/delete/0/?objid2perf_character=${perfcharacterObjid}&objid2cfg_group=${strRemoveRegionGrp}`;
      deleteCountry(url);
      updateExcludedList([]);
    }
  };

  const renderList = () => {
    if (isListOpen) {
      return (
        <Card className="country-list">
          <CardHeader className="pl-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="mb-0">
                    Hide me from region
              </h6>
              {/* <i className="fa fa-times" aria-hidden="true"  /> */}
              <svg onClick={openCountriesList} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.292894 0.292893C-0.0976312 0.683417 -0.0976312 1.31658 0.292894 1.70711L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292894 0.292893Z" fill="#5D6569" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976309 11.3166 -0.0976309 10.6834 0.292893 10.2929L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893Z" fill="#5D6569" />
              </svg>

            </div>
            <Input type="text" onChange={handleSearch} placeholder="Search Region" className="searchRegionField" />
          </CardHeader>
          <CardBody className="pt-0">
            <ListGroup className="checkBoxList">
              <ListGroupItem className="" id="selectAllOption">
                <div className="customCheckbox">
                  <Input type="checkbox" id="selectall" aria-label="" onClick={handleSelectAll} />
                  <Label htmlFor="selectall">Select All</Label>
                </div>
              </ListGroupItem>
              {countryStateListLoading ? <div className="loderWraper"><LoadingSpinner /></div> : formatedOptionList }
            </ListGroup>
          </CardBody>
          <CardFooter>
            {isShowError ? <small style={{ color: '#f07563' }}>Please select region</small> : '' }
            <Button color="primary" block onClick={excludeCountry} className="ftBtn text-white">DONE</Button>
          </CardFooter>
        </Card>
      );
    }
    return '';
  };

  const renderTagSection = () => {
    if (tagListLoading) {
      return <LoadingSpinner />;
    }

    if (excludedTagList) {
      return (
        <Row className="taglistROw">
          {excludedTagList}
        </Row>
      );
    }
    return '';
  };

  return (
    <main className="mt-3" style={{ minHeight: '500px' }}>
      <div className="headTitle">Hide Your Profile From Specific Location</div>
      <section className="vis-list-section mt-4 position-relative">
        <Row>
          <Col md={10}>
            <Button color="primary" className="text-white hideMeBtn" onClick={openCountriesList}>Hide me from region</Button>
          </Col>
          <Col md={2} className="text-right">
            <Button outline color="primary" className="clearAllBtn" onClick={removeAllExcudedRegion}>Clear all</Button>
          </Col>
        </Row>
        {renderList()}
      </section>
      <section className="vis-hide-cntry-tag mt-4">
        {renderTagSection()}
      </section>
    </main>
  );
};

Visibility.defaultProps = {
  perfcharacterObjid: 0,
  excludedList: [],
  tagListLoading: false,
  regionList: [],
  countryStateListLoading: false,
};

Visibility.propTypes = {
  actionExcludedRegionList: PropTypes.func.isRequired,
  perfcharacterObjid: PropTypes.number,
  excludedList: PropTypes.instanceOf(Array),
  tagListLoading: PropTypes.bool,
  deleteCountry: PropTypes.func.isRequired,
  updateExcludedList: PropTypes.func.isRequired,
  getRegionList: PropTypes.func.isRequired,
  regionList: PropTypes.instanceOf(Array),
  countryStateListLoading: PropTypes.bool,
  addExcludeRegion: PropTypes.func.isRequired,
};

export default Visibility;
